import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import type { AddRulesFormProps } from "./types";

const AddRulesForm: React.FC<AddRulesFormProps> = ({
	currentEdit,
	setCurrentEdit,
	ruleTypes,
	handleNewRule,
	saveInclusion,
	setShowAddForm,
}) => {
	return (
		<Grid container>
			<Grid item xs={12}>
				<Autocomplete
					value={currentEdit.type}
					onChange={(e: any, newValue: string | null) =>
						setCurrentEdit((prevState: any) => ({
							...prevState,
							type: newValue,
						}))
					}
					options={ruleTypes}
					sx={{ margin: "1rem 0" }}
					renderInput={(params: any) => (
						<TextField {...params} label="Rule Type" />
					)}
				/>
				<TextField
					required
					label={
						currentEdit.type ? "Add new " + currentEdit.type : "Add new Rule"
					}
					name="rule"
					variant="outlined"
					fullWidth
					value={currentEdit.text}
					onChange={(e: any) => handleNewRule("text", e)}
					sx={{ marginBottom: "1rem" }}
				/>
				<Button
					variant="contained"
					color="primary"
					onClick={saveInclusion}
					disabled={!(currentEdit.type && currentEdit.text)}
				>
					Add
				</Button>
				<Button
					variant="contained"
					color="secondary"
					onClick={() => setShowAddForm(false)}
					className="margin-left-1"
				>
					Cancel
				</Button>
			</Grid>
		</Grid>
	);
};

export default AddRulesForm;
