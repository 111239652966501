// @ts-nocheck
import DescriptionIcon from "@mui/icons-material/Description";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { ChartHeader } from "../ChartHeader";
import CustomMarkdown from "./CustomMarkDown";
import type { PatientDetailsHeaderProps } from "./types";

const PatientDetailsHeader: React.FC<PatientDetailsHeaderProps> = ({
	patientId,
	codeData,
	customStyles,
	highlightString,
}) => {
	return (
		<Paper square={false} elevation={3} className="paperHeight">
			<ChartHeader patientId={patientId} visitType={codeData.type_of_visit} />
			<Box className="text-left">
				<Grid container alignItems="center" spacing={1}>
					<Grid item>
						<DescriptionIcon />
					</Grid>
					<Grid item>
						<Typography level="title-lg" style={customStyles}>
							Review
						</Typography>
					</Grid>
				</Grid>
				<Typography style={customStyles}>
					<CustomMarkdown
						content={codeData.raw_chart}
						highlightString={highlightString}
					/>
				</Typography>
			</Box>
		</Paper>
	);
};

export default PatientDetailsHeader;
