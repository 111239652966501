import AddIcon from "@mui/icons-material/Add";
import BackupIcon from "@mui/icons-material/Backup";
import DeleteIcon from "@mui/icons-material/Delete";
import {
	Alert,
	AlertTitle,
	Button,
	FormControlLabel,
	Grid,
	IconButton,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import { Card, CardContent } from "@mui/material";
import type React from "react";
import { useEffect, useState } from "react";

interface CPTCode {
	code: string;
	units: number;
	modifiers: string[];
}

interface ProcessedResult {
	icd_codes: string[];
	cpt_codes: CPTCode[];
}

const CodeExtraction = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [result, setResult] = useState<ProcessedResult | null>(null);
	const [imagePreview, setImagePreview] = useState<string | null>(null);
	const [isEditMode, setIsEditMode] = useState(false);
	const [editableData, setEditableData] = useState<ProcessedResult | null>(
		null,
	);

	// Update editableData when result changes
	useEffect(() => {
		if (result) {
			setEditableData(result);
		}
	}, [result]);

	const validateStructure = (data: unknown): data is ProcessedResult => {
		try {
			if (!data || typeof data !== "object") return false;

			const typedData = data as ProcessedResult;

			// Validate ICD codes
			if (
				!Array.isArray(typedData.icd_codes) ||
				!typedData.icd_codes.every((code: unknown) => typeof code === "string")
			) {
				return false;
			}

			// Validate CPT codes
			if (!Array.isArray(typedData.cpt_codes)) return false;

			return typedData.cpt_codes.every(
				(cpt: unknown) =>
					typeof cpt === "object" &&
					cpt !== null &&
					typeof (cpt as CPTCode).code === "string" &&
					typeof (cpt as CPTCode).units === "number" &&
					Array.isArray((cpt as CPTCode).modifiers) &&
					(cpt as CPTCode).modifiers.every(
						(mod: unknown) => typeof mod === "string",
					),
			);
		} catch {
			return false;
		}
	};

	const handleFileUpload = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const file = event.target.files?.[0];
		if (!file) return;

		if (!file.type.startsWith("image/")) {
			setError("Please upload an image file");
			return;
		}

		const imageUrl = URL.createObjectURL(file);
		setImagePreview(imageUrl);

		setLoading(true);
		setError("");

		try {
			// Convert image to base64
			const base64 = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.onloadend = () => resolve(reader.result);
				reader.readAsDataURL(file);
			});

			const data = await fetch("http://localhost:8000/process-image", {
				method: "POST",
				body: JSON.stringify({ image_url: base64 }),
			});
			console.log("data", data);
			const response = await data.json();
			console.log("response", response);
			const result = response.result.replace(/\n/g, "");
			console.log("result", result);
			const parsedData = JSON.parse(result);
			console.log("parsedData", parsedData);
			if (!validateStructure(parsedData)) {
				throw new Error("Invalid data structure received from API");
			}

			setResult(parsedData);
		} catch (err) {
			setError(err instanceof Error ? err.message : "Error processing image");
		} finally {
			setLoading(false);
		}
	};

	const handleUploadClick = () => {
		const fileInput = document.getElementById(
			"file-upload",
		) as HTMLInputElement;
		if (fileInput) {
			fileInput.click();
		}
	};

	const handleICDCodeChange = (index: number, value: string) => {
		if (!editableData) return;
		const newICDCodes = [...editableData.icd_codes];
		newICDCodes[index] = value;
		setEditableData({ ...editableData, icd_codes: newICDCodes });
	};

	const handleCPTCodeChange = (
		index: number,
		field: string,
		value: string | number,
	) => {
		if (!editableData) return;
		const newCPTCodes = [...editableData.cpt_codes];
		if (field === "modifiers") {
			const modifierArray = String(value)
				.split(",")
				.map((m) => m.trim());
			newCPTCodes[index] = { ...newCPTCodes[index], [field]: modifierArray };
		} else {
			newCPTCodes[index] = { ...newCPTCodes[index], [field]: value };
		}
		setEditableData({ ...editableData, cpt_codes: newCPTCodes });
	};

	const handleModifierChange = (
		cptIndex: number,
		modIndex: number,
		value: string,
	) => {
		if (!editableData) return;
		const newCPTCodes = [...editableData.cpt_codes];
		newCPTCodes[cptIndex].modifiers[modIndex] = value;
		setEditableData({ ...editableData, cpt_codes: newCPTCodes });
	};

	const addICDCode = () => {
		if (!editableData) return;
		setEditableData({
			...editableData,
			icd_codes: [...editableData.icd_codes, ""],
		});
	};

	const removeICDCode = (index: number) => {
		if (!editableData) return;
		const newICDCodes = editableData.icd_codes.filter((_, i) => i !== index);
		setEditableData({ ...editableData, icd_codes: newICDCodes });
	};

	const addCPTCode = () => {
		if (!editableData) return;
		setEditableData({
			...editableData,
			cpt_codes: [
				...editableData.cpt_codes,
				{ code: "", units: 1, modifiers: [] },
			],
		});
	};

	const removeCPTCode = (index: number) => {
		if (!editableData) return;
		const newCPTCodes = editableData.cpt_codes.filter((_, i) => i !== index);
		setEditableData({ ...editableData, cpt_codes: newCPTCodes });
	};

	const renderEditMode = () => (
		<div className="space-y-4">
			<div className="flex justify-between items-center mb-4">
				<h3 className="text-lg font-semibold text-gray-800">CPT Codes</h3>
				<Button startIcon={<AddIcon />} variant="outlined" onClick={addCPTCode}>
					Add Code
				</Button>
			</div>

			{/* Header row */}
			<div className="flex gap-4 px-4 py-2 bg-gray-50 rounded-t-lg font-medium text-sm text-gray-600">
				<div className="w-[200px]">Code</div>
				<div className="w-[100px]">Units</div>
				<div className="flex-1">Modifiers</div>
				<div className="w-[48px]" />
			</div>

			{/* CPT Code rows */}
			<div className="space-y-2">
				{editableData?.cpt_codes.map((cpt, index) => (
					<div
						key={`cpt-${cpt.code}` || `cpt-${index}`}
						className="flex items-center gap-4 px-4 py-2 bg-white border border-gray-200 rounded-lg"
					>
						<input
							type="text"
							value={cpt.code}
							onChange={(e) =>
								handleCPTCodeChange(index, "code", e.target.value)
							}
							className="w-[200px] px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
						/>

						<input
							type="text"
							value={cpt.units}
							onChange={(e) =>
								handleCPTCodeChange(index, "units", e.target.value)
							}
							className="w-[100px] px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
						/>

						<input
							type="text"
							value={cpt.modifiers.join(", ")}
							onChange={(e) =>
								handleCPTCodeChange(index, "modifiers", e.target.value)
							}
							placeholder="e.g. TC, QW"
							className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
						/>

						<IconButton
							onClick={() => removeCPTCode(index)}
							size="small"
							color="error"
							className="w-[48px]"
						>
							<DeleteIcon />
						</IconButton>
					</div>
				))}
			</div>
		</div>
	);

	const renderPreviewMode = () => (
		<div className="space-y-6">
			<div className="bg-gray-50 rounded-lg p-4">
				<h3 className="text-lg font-semibold text-gray-800 mb-3">ICD Codes</h3>
				<div className="grid grid-cols-2 gap-2">
					{editableData?.icd_codes.map((code, index) => (
						<div
							key={`icd-${code}` || `icd-${index}`}
							className="bg-white px-4 py-2 rounded border border-gray-200 shadow-sm"
						>
							{code}
						</div>
					))}
				</div>
			</div>

			<div className="bg-gray-50 rounded-lg p-4" style={{ width: "100%" }}>
				<h3 className="text-lg font-semibold text-gray-800 mb-3">CPT Codes</h3>
				<div style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
					<table style={{ width: "100%", borderCollapse: "collapse" }}>
						<colgroup>
							<col style={{ width: "33.33%" }} />
							<col style={{ width: "33.33%" }} />
							<col style={{ width: "33.33%" }} />
						</colgroup>
						<thead>
							<tr>
								<th
									style={{
										padding: "12px 16px",
										textAlign: "left",
										backgroundColor: "#f9fafb",
										borderBottom: "1px solid #e5e7eb",
									}}
								>
									Code
								</th>
								<th
									style={{
										padding: "12px 16px",
										textAlign: "left",
										backgroundColor: "#f9fafb",
										borderBottom: "1px solid #e5e7eb",
									}}
								>
									Units
								</th>
								<th
									style={{
										padding: "12px 16px",
										textAlign: "left",
										backgroundColor: "#f9fafb",
										borderBottom: "1px solid #e5e7eb",
									}}
								>
									Modifiers
								</th>
							</tr>
						</thead>
						<tbody>
							{editableData?.cpt_codes.map((cpt, index) => (
								<tr
									key={cpt.code || `cpt-${index}`}
									style={{ borderBottom: "1px solid #e5e7eb" }}
								>
									<td style={{ padding: "12px 16px" }}>{cpt.code}</td>
									<td style={{ padding: "12px 16px" }}>{cpt.units}</td>
									<td style={{ padding: "12px 16px" }}>
										{cpt.modifiers.map((mod, i) => (
											<span
												key={`mod-${mod}` || `mod-${i}`}
												style={{
													display: "inline-block",
													backgroundColor: "#f3f4f6",
													padding: "4px 8px",
													borderRadius: "9999px",
													marginRight: "4px",
													marginBottom: "4px",
												}}
											>
												{mod}
											</span>
										))}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);

	return (
		<>
			{/* Title Section */}
			<Typography
				variant="h4"
				component="h1"
				sx={{
					textAlign: "center",
					my: 4,
					fontWeight: "bold",
					color: "primary.main",
				}}
			>
				Code Extractor
			</Typography>

			<Grid
				container
				spacing={3}
				sx={{
					p: 4,
					"& .MuiCard-root": {
						// Make all cards same height
						height: "100%",
						display: "flex",
						flexDirection: "column",
					},
				}}
			>
				{/* Left Column - File Upload */}
				<Grid item xs={12} md={6}>
					<Card>
						<CardContent
							sx={{ flex: 1, display: "flex", flexDirection: "column" }}
						>
							<div className="space-y-4 flex-1">
								<div
									className="border-2 border-dashed rounded-lg p-6 text-center cursor-pointer hover:bg-gray-50 flex-1 flex flex-col items-center justify-center"
									onClick={handleUploadClick}
									onKeyDown={(e) => e.key === "Enter" && handleUploadClick()}
									role="button"
									tabIndex={0}
								>
									{imagePreview ? (
										<img
											src={imagePreview}
											alt="Uploaded preview"
											className="max-h-64 mx-auto mb-4"
										/>
									) : (
										<BackupIcon
											sx={{
												fontSize: 64,
												color: "primary.main",
												mb: 2,
												opacity: 0.8,
											}}
										/>
									)}
									<div className="mt-2">
										<label
											htmlFor="file-upload"
											className="cursor-pointer text-blue-600 hover:text-blue-500 font-medium"
										>
											Upload an image
										</label>
										<p className="text-gray-500 mt-1 text-sm">
											Click or drag and drop your file here
										</p>
										<input
											id="file-upload"
											type="file"
											className="hidden"
											accept="image/*"
											onChange={handleFileUpload}
										/>
									</div>
								</div>

								{loading && (
									<div className="text-center">Processing image...</div>
								)}

								{error && (
									<Alert severity="error">
										<AlertTitle>{error}</AlertTitle>
									</Alert>
								)}
							</div>
						</CardContent>
					</Card>
				</Grid>

				{/* Right Column - Results */}
				<Grid item xs={12} md={6}>
					<Card>
						<CardContent>
							<div className="mb-4">
								<FormControlLabel
									control={
										<Switch
											checked={isEditMode}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												setIsEditMode(e.target.checked)
											}
										/>
									}
									label={isEditMode ? "Edit Mode" : "Preview Mode"}
								/>
							</div>

							{editableData ? (
								isEditMode ? (
									renderEditMode()
								) : (
									renderPreviewMode()
								)
							) : (
								<div className="text-center text-gray-500 py-8">
									Upload an image to see extracted codes
								</div>
							)}
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};

export default CodeExtraction;
