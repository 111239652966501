import dotenv from "dotenv";
import mixpanel from "mixpanel-browser";
import type {
	CPTCode,
	CodeData,
	CodeUnit,
} from "./components/PatientDetails/types";
import { supabase } from "./services/supabase";

dotenv.config();

export const getComments = async (chart_id: string) => {
	const { data, error } = await supabase
		.from("comments")
		.select(
			"chart_id, code_type, code, comment, comment_by, created_at, updated_at, id",
		)
		.eq("chart_id", chart_id);
	return { data, error };
};

export const saveComment = async ({
	chart_id,
	code_type,
	code,
	comment,
	comment_by,
}: {
	chart_id: string;
	code_type: string;
	code: string;
	comment: string;
	comment_by: string;
}) => {
	const { data, error } = await supabase
		.from("comments")
		.insert([
			{
				chart_id,
				code_type,
				code,
				comment,
				comment_by,
				created_at: new Date(),
				updated_at: new Date(),
			},
		])
		.select("id");
	return { data, error };
};

export const updateComment = async ({
	comment_id,
	new_comment,
	comment_by,
}: {
	comment_id: string;
	new_comment: string;
	comment_by: string;
}) => {
	const { data, error } = await supabase
		.from("comments")
		.update({
			comment: new_comment,
			comment_by,
			updated_at: new Date(),
		})
		.match({ id: comment_id });
	return { data, error };
};

export const deleteComment = async (codeOfComment: string) => {
	const { data, error } = await supabase
		.from("comments")
		.delete()
		.match({ id: codeOfComment });
	return { data, error };
};

export const getCommentCount = async (chart_id: string) => {
	const { count, error } = await supabase
		.from("comments")
		.select("id", { count: "exact", head: true })
		.eq("chart_id", chart_id);
	return { count, error };
};

export const getBucketObject = async (url: string) => {
	const { data, error } = await supabase.storage
		.from("encounter-uploads")
		.download(url);
	return { data, error };
};

export const getEncounterData = async (chart_id?: string) => {
	if (chart_id) {
		const { data, error } = await supabase
			.from("encounters")
			.select(
				"raw_chart, icd_codes_v3, icd_codes_v2, cpt_codes_v3, uploaded_files, chart_id, icd_codes, cpt_codes_v2, rationale, status, type_of_visit, has_coder_submitted, has_qa_submitted",
			)
			.eq("chart_id", chart_id)
			.single();

		if (error) {
			console.error("Error fetching encounter data:", error);
			return { data: null, error: error };
		}

		if (data.uploaded_files[0]) {
			const url = data.uploaded_files[0].url;
			const name = data.uploaded_files[0].name;
			const fileType = name.split(".").pop()?.toLowerCase();
			if (
				fileType === "md" &&
				(!data.raw_chart || data.raw_chart.trim() === "")
			) {
				const { data: bucketData, error: bucketError } =
					await getBucketObject(url);

				if (bucketError) {
					console.error("Error downloading file:", bucketError);
				}
				try {
					const arrayBuffer = await bucketData?.arrayBuffer();
					const decoder = new TextDecoder("utf-8");
					const content = decoder.decode(arrayBuffer);

					const newData = {
						...data,
						raw_chart: content,
					};
					return { data: newData, error: null };
				} catch (decodeError) {
					console.error("Error decoding file content:", decodeError);
					return { data: null, error: decodeError };
				}
			}
		}
		return { data, error: null };
	}
	const { data, error } = await supabase
		.from("encounters")
		.select(
			"raw_chart, icd_codes_v3, icd_codes_v2, cpt_codes_v3, chart_id, icd_codes, cpt_codes_v2, rationale, status, type_of_visit",
		)
		.not("icd_codes_v3", "is", null)
		.not("cpt_codes_v3", "is", null);

	if (data) {
		const filteredData = data
			.map((encounter: any) => ({
				...encounter,
				icd_codes_v3: encounter.icd_codes_v3.filter(
					(code: any) => code.status !== "deleted",
				),
				cpt_codes_v3: encounter.cpt_codes_v3.filter(
					(code: any) => code.status !== "deleted",
				),
			}))
			.filter(
				(encounter: any) =>
					encounter.icd_codes_v3.length > 0 ||
					encounter.cpt_codes_v3.length > 0,
			);
		return { data: filteredData, error };
	}
	return { data: null, error };
};

export const updateEncounterData = async (
	chart_id: string,
	updatedData: Partial<{
		cpt_codes_v3: Array<{
			code: string;
			description: string;
			justification: string;
			emFlag: boolean;
		}>;
		icd_codes_v3: Array<{
			tag: string;
			code: string;
			highlight: string;
			description: string;
			justification: string;
		}>;
	}>,
) => {
	const { data, error } = await supabase
		.from("encounters")
		.update(updatedData)
		.eq("chart_id", chart_id);

	return { data, error };
};

export const updateEncounterRawChart = async (
	chart_id: number,
	updatedData: {
		raw_chart: string;
	},
) => {
	const { data, error } = await supabase
		.from("encounters")
		.update(updatedData)
		.eq("chart_id", chart_id);

	return { data, error };
};

export const saveCodeToEncounter = async (
	chart_id: string,
	updatedCodeData: any,
) => {
	const { data, error } = await supabase
		.from("encounters")
		.update({
			icd_codes_v3: updatedCodeData.icd_codes_v3,
			cpt_codes_v3: updatedCodeData.cpt_codes_v3,
		})
		.match({ chart_id });
	return { data, error };
};

export const deleteCodeFromEncounter = async (
	chart_id: string,
	code: string,
	codeType: string,
) => {
	const encounterData = await getEncounterData(chart_id);
	if (encounterData.error) {
		return { data: null, error: encounterData.error };
	}

	const updatedCodeData: CodeData = { ...(encounterData.data as CodeData) };
	// Not deleting record from DB, instead marking it as deleted
	if (codeType === "icd") {
		updatedCodeData.icd_codes_v3 = updatedCodeData.icd_codes_v3.map(
			(codeData: any) =>
				codeData.code === code
					? { ...codeData, status: "deleted", source: "human" }
					: codeData,
		);
	} else {
		updatedCodeData.cpt_codes_v3 = updatedCodeData.cpt_codes_v3.map(
			(codeData: any) =>
				codeData.code === code
					? { ...codeData, status: "deleted", source: "human" }
					: codeData,
		);
	}

	const { data, error } = await saveCodeToEncounter(chart_id, updatedCodeData);
	return { data, error };
};

export const fetchRule = async (code: string) => {
	const { data, error } = await supabase
		.from("rules")
		.select()
		.match({ code: code });
	return { data, error };
};

export const generateChartId = () =>
	Math.floor(100000 + Math.random() * 900000);

export const uploadFile = async (fileContent: Blob, fileName: string) => {
	const { data, error } = await supabase.storage
		.from("encounter-uploads")
		.upload(fileName, fileContent);

	if (error) throw error;
	return data.path;
};

export const uploadAudioFile = async (fileContent: Blob, fileName: string) => {
	const { data, error } = await supabase.storage
		.from("encounter-uploads")
		.upload(fileName, fileContent, {
			contentType: "audio/mpeg",
		});

	if (error) throw error;
	return data.path;
};

export const createEncounter = async (
	chartId: number,
	fileName: string,
	filePath: string,
) => {
	const { error } = await supabase.from("encounters").insert({
		chart_id: chartId,
		uploaded_files: [{ name: fileName, url: filePath }],
	});

	if (error) throw error;
};

export const mixpanelConfig = (email: string) => {
	mixpanel.init("5caadd2d7aa2552bb2e8d28bfc910e8e", {
		track_pageview: true,
		persistence: "localStorage",
	});
	mixpanel.identify(email);
	mixpanel.people.set({
		$email: email,
	});
	return mixpanel;
};

export const isObjectEmpty = (obj: Record<string, any>): boolean => {
	return obj && Object.keys(obj).length === 0;
};

export const updateCoderSubmissionStatus = async (
	chartId: number,
	status: boolean,
) => {
	const { data, error } = await supabase
		.from("encounters")
		.update({ has_coder_submitted: status })
		.eq("chart_id", chartId);

	return { data, error };
};

export const updateQASubmissionStatus = async (
	chartId: number,
	status: boolean,
) => {
	try {
		const { data, error } = await supabase
			.from("encounters")
			.update({ has_qa_submitted: status })
			.eq("chart_id", chartId);

		if (error) throw error;
		return data;
	} catch (error) {
		console.error("Error updating QA submission status:", error);
		throw error;
	}
};

export const updateCoderSavedUnits = async (
	chartId: number,
	updatedData: CPTCode[],
) => {
	try {
		const { data, error } = await supabase
			.from("encounters")
			.update({ cpt_codes_v3: updatedData })
			.eq("chart_id", chartId);

		if (error) throw error;
		return data;
	} catch (error) {
		console.error("Error updating coder saved units:", error);
		throw error;
	}
};
