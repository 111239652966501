import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import { Typography } from "@mui/joy";

import { customStyles } from "../constants";

import {
  Grid,
} from "@mui/material";

export const ChartHeader = (params: any) => {
  const { patientId, visitType } = params;
  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <AccountCircleTwoToneIcon />
          </Grid>
          <Grid item>
            <Typography level="title-lg" style={customStyles}>
              Patient Encounter
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography
              level="body-sm"
              className="encounterTitle grey-title-color"
              style={customStyles}
            >
              ID
            </Typography>
            <Typography
              level="body-sm"
              className="encounterCaption"
              style={customStyles}
            >
              {patientId}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              level="body-sm"
              className="encounterTitle grey-title-color"
              style={customStyles}
            >
              Visit Type
            </Typography>
            <Typography
              level="body-sm"
              className="encounterCaption"
              style={customStyles}
            >
              {visitType || "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
