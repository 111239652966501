import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { Typography } from "@mui/joy";

import { customStyles, encounterStatus } from "../constants";

export const CodeStages = (codeData: any) => {
  const getStatusOpacity = (chipStatus: string) => {
    return encounterStatus.indexOf(chipStatus) <=
      encounterStatus.indexOf(codeData.status || "in-progress")
      ? 1
      : 0.2;
  };

  return (
    <>
    <CheckCircleIcon
      fontSize="small"
      color="success"
      sx={{
        opacity: getStatusOpacity("in-progress"),
        marginRight: 0.5,
      }}
    />
    <Typography
      level="body-xs"
      sx={{ marginRight: 2 }}
      style={customStyles}
    >
      In Progress
    </Typography>
    <CheckCircleIcon
      fontSize="small"
      color="success"
      sx={{
        opacity: getStatusOpacity("review"),
        marginRight: 0.5,
      }}
    />
    <Typography
      level="body-xs"
      sx={{ marginRight: 2 }}
      style={customStyles}
    >
      Review
    </Typography>
    <CheckCircleIcon
      fontSize="small"
      color="success"
      sx={{
        opacity: getStatusOpacity("completed"),
        marginRight: 0.5,
      }}
    />
    <Typography
      level="body-xs"
      sx={{ marginRight: 2 }}
      style={customStyles}
    >
      Complete
    </Typography>
    <CheckCircleIcon
      fontSize="small"
      color="success"
      sx={{
        opacity: getStatusOpacity("final"),
        marginRight: 0.5,
      }}
    />
    <Typography
      level="body-xs"
      sx={{ marginRight: 2 }}
      style={customStyles}
    >
      Final
    </Typography>
    </>
  )
};
