/* Import React utils */
import { BrowserRouter, Route, Routes } from "react-router-dom";

/* Import components */
import CommonLayout from "./components/CommonLayout";
import { AuthProvider } from "./middlewares/AuthContext";
import LoginPage from "./pages/Login";
import PatientDetails from "./pages/patientDetails";

/* Import styles */
import "./App.css";
import ProtectedRoute from "./middlewares/ProtectedRoute";
import CodeExtraction from "./pages/CodeExtraction";
import CodesListing from "./pages/CodesListing";
import ItemListing from "./pages/ItemListing";
import NotAuthorized from "./pages/NotAuthorized";
import NotFound from "./pages/NotFound";
import Roles from "./pages/Roles";
import Users from "./pages/Users";
import UploadFiles from "./pages/uploadFiles";

function App() {
	return (
		<AuthProvider>
			<BrowserRouter>
				<div className="App">
					<Routes>
						<Route path="/" element={<LoginPage />} />
						<Route
							path="/home"
							element={
								<ProtectedRoute requiredRole="any">
									<CommonLayout>
										<ItemListing />
									</CommonLayout>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/patient-details/:patientId"
							element={
								<ProtectedRoute requiredRole="any">
									<CommonLayout>
										<PatientDetails />
									</CommonLayout>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/upload-files"
							element={
								<ProtectedRoute requiredRole="admin">
									<CommonLayout>
										<UploadFiles />
									</CommonLayout>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/rules"
							element={
								<ProtectedRoute requiredRole="admin">
									<CommonLayout>
										<CodesListing />
									</CommonLayout>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/roles"
							element={
								<ProtectedRoute requiredRole="add_roles">
									<CommonLayout>
										<Roles />
									</CommonLayout>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/users"
							element={
								<ProtectedRoute requiredRole="add_users">
									<CommonLayout>
										<Users />
									</CommonLayout>
								</ProtectedRoute>
							}
						/>
						<Route
							path="/extraction"
							element={
								<ProtectedRoute requiredRole="add_users">
									<CodeExtraction />
								</ProtectedRoute>
							}
						/>
						<Route path="/not-authorized" element={<NotAuthorized />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</div>
			</BrowserRouter>
		</AuthProvider>
	);
}

export default App;
