import React from "react";

const NotFound: React.FC = () => {
  return (
    <div>
      <h1>404! Not Found</h1>
      <p>
        The page you are looking for doesn't exist, Please contact admin for any
        queries.
      </p>
      <p>
        Visit <a href="/">home page</a>.
      </p>
    </div>
  );
};

export default NotFound;
