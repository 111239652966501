import React from "react";
import {
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";

interface Rule {
  id: number;
  code: string;
  inclusion: string;
  exclusion: string;
  source: string;
}

export const RuleList: React.FC<{
  rules: Rule[];
  type: "inclusion" | "exclusion";
  currentEdit: any;
  handleRuleUpdate: (
    id: number,
    type: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRuleSubmit: () => void;
  handleDeleteIconClick: (id: number, ruleType: string, code: string) => void;
  setCurrentEdit: React.Dispatch<React.SetStateAction<any>>;
  setShowAddForm: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCode: string | null;
}> = ({
  rules,
  type,
  currentEdit,
  handleRuleUpdate,
  handleRuleSubmit,
  handleDeleteIconClick,
  setCurrentEdit,
  setShowAddForm,
  selectedCode,
}) => (
  <Box mt={2}>
    <Typography variant="h5">
      {type.charAt(0).toUpperCase() + type.slice(1)}s
    </Typography>
    <List>
      {rules.map(({ [type]: ruleText, source, id }) => (
        <ListItem key={id} className="flex items-center">
          {currentEdit.id === id ? (
            <Box className="flex items-center">
              <TextField
                variant="outlined"
                fullWidth
                value={currentEdit.text}
                onChange={handleRuleUpdate(id, type)}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleRuleSubmit}
                className="margin-left-1"
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  setCurrentEdit({
                    id: 0,
                    text: "",
                    type: "",
                  })
                }
                className="margin-left-1"
              >
                Cancel
              </Button>
            </Box>
          ) : (
            <ListItemText primary={ruleText} />
          )}
          {source === "user" && currentEdit.id !== id && (
            <>
              <EditIcon
                fontSize="small"
                onClick={() => {
                  setCurrentEdit({
                    id: id,
                    text: ruleText,
                    type: type,
                  });
                  setShowAddForm(false);
                }}
                sx={{ cursor: "pointer" }}
              />
              <Delete
                fontSize="small"
                onClick={() => handleDeleteIconClick(id, type, selectedCode!)}
                className="margin-left-1"
                sx={{ cursor: "pointer" }}
              />
            </>
          )}
        </ListItem>
      ))}
    </List>
  </Box>
);
