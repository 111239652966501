import { Box, Button, Paper, Typography } from "@mui/material";
import type { FileModeProps } from "./types";

const FileMode: React.FC<FileModeProps> = ({ uploadFile }) => {
	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		const files = event.dataTransfer.files;
		if (files?.length) {
			handleFilesUpload(files);
		}
	};

	const handleFilesUpload = (fileList: FileList) => {
		const dataTransfer = new DataTransfer();
		for (let i = 0; i < fileList?.length; i++) {
			dataTransfer.items.add(fileList[i]);
		}
		const event = {
			target: {
				files: dataTransfer.files,
			},
		} as React.ChangeEvent<HTMLInputElement>;

		uploadFile(event);
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
	};
	return (
		<Paper
			variant="outlined"
			sx={{ p: 6, mt: 2, backgroundColor: "#F2F2F2", borderStyle: "dashed" }}
			onDrop={handleDrop}
			onDragOver={handleDragOver}
		>
			<Box textAlign="center">
				<input
					type="file"
					accept=".md, .pdf, .docx"
					multiple
					onChange={uploadFile}
					style={{ display: "none" }}
					id="file-upload"
				/>
				<label htmlFor="file-upload">
					<Button variant="contained" component="span">
						Choose Files
					</Button>
				</label>
				<Typography variant="body2" color="textSecondary">
					*Only markdown, .pdf and .docx files are supported
				</Typography>
			</Box>
		</Paper>
	);
};

export default FileMode;
