// @ts-nocheck
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	styled,
} from "@mui/material";

interface CodeDeleteDialogProps {
	openDeleteDialog: boolean;
	handleCloseDeleteDialog: () => void;
	customStyles: unknown;
	handleConfirmDelete: () => void;
}

const YesButton = styled(Button)({
	backgroundColor: "#1976d2",
	color: "white",
	"&:hover": {
		backgroundColor: "#115293",
	},
});

const CodeDeleteDialog = ({
	openDeleteDialog,
	handleCloseDeleteDialog,
	customStyles,
	handleConfirmDelete,
}: CodeDeleteDialogProps) => {
	return (
		<Dialog
			open={openDeleteDialog}
			onClose={handleCloseDeleteDialog}
			aria-labelledby="delete-dialog-title"
			aria-describedby="delete-dialog-description"
		>
			<DialogTitle id="delete-dialog-title">{"Delete Code"}</DialogTitle>
			<DialogContent>
				<DialogContentText id="delete-dialog-description" style={customStyles}>
					Are you sure you want to delete this code?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleCloseDeleteDialog}
					color="secondary"
					className="secondary"
					style={customStyles}
				>
					No
				</Button>
				<YesButton onClick={handleConfirmDelete}>Yes</YesButton>
			</DialogActions>
		</Dialog>
	);
};

export default CodeDeleteDialog;
