export const remoteURL = "https://api.neuraoak.com";

export const listTableColumns = [
	{ title: "Type of Visit", column: "type_of_visit" },
	{ title: "Encounter ID", column: "chart_id" },
	{ title: "# of billable codes", column: "icd_codes_v2" },
	{ title: "Status", column: "status" },
];

export const APIEndpoints = {
	comments: {
		fetch: (chartId: string) => `/comment/${chartId}`,
		add: "/comment",
		update: "/comment",
		delete: "/comment",
	},
};

export const anyRole = "any";
export const adminAccess = "all_access";
export const encounterStatus = ["in-progress", "review", "completed", "final"];
export const ruleTypes = ["inclusion", "exclusion"];

export const customStyles = {
	fontFamily:
		'-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
	fontSize: "0.9rem",
};

export const codeUnitsTableColumns = [
	{ column: "code_type", title: "Code Type", width: "15%" },
	{ column: "code", title: "Code", width: "15%" },
	{ column: "units", title: "Units", width: "20%" },
	{ column: "add_modifier", title: "Add Modifier", width: "20%" },
	{ column: "modifiers", title: "Modifiers", width: "30%" },
];

export const modifiers = {
	"21": "Prolonged E&M Service",
	"24": "Unrelated E/M during post-op period",
	"25": "Separately and identifiable E/M service on same day as a minor surgical procedure",
	"22": "Unusual Procedural Service",
	"76": "Repeat Procedure or Service by Same Physician or Other Qualified Health Care Professional",
	"77": "Repeat Procedure or Service by Another Physician or Other Qualified Health Care Professional",
	"78": "Unplanned Return to the Operating/Procedure Room by the Same Physician or Other Qualified Health Care Professional Following Initial Procedure for a Related Procedure During the Postoperative Period",
	"79": "Unrelated procedure by same physician during post-op period",
	"52": "Reduced Services",
	"53": "Discontinued Procedure after anesthesia",
	"54": "Surgical Care Only (someone else providing care)",
	"55": "Postoperative Management Only",
	"73": "Discontinued Outpatient Hospital / ASC Procedure prior to administration of anesthesia",
	"74": "Discontinued Outpatient Hospital / ASC Procedure after administration of anesthesia",
	"50": "Bilateral Procedure: Unless otherwise identified in the listings, bilateral procedures that are performed at the same session, should be identified by adding modifier 50 to the appropriate 5 digit code",
	"51": "Multiple procedures (many insurances, such as Medicare, electronically add this to certain CPT codes and they ask that you do not append this modifier)",
	"58": "Staged procedure",
	"59": "Distinct procedural service when no other modifier will suffice",
	"57": "Decision for major surgery based upon the E/M done today",
	LT: "Left foot",
	TA: "Left great toe",
	T1: "2nd toe, left foot",
	T2: "3rd toe, left foot",
	T3: "4th toe, left foot",
	T4: "5th toe, left foot",
	RT: "Right foot",
	T5: "Right great toe",
	T6: "2nd toe, right foot",
	T7: "3rd toe, right foot",
	T8: "4th toe, right foot",
	T9: "5th toe, right foot",
	Q5: "Service provided by substitute physician under reciprocal billing arrangement",
	Q6: "Services provided by a locum tenens physician",
	Q7: "One Class A finding",
	Q8: "Two Class B findings",
	Q9: "One Class B and Two Class C findings",
	AS: "Assistant at Surgery of a physician assistant, nurse practitioner or clinical nurse practitioner",
	GC: "Service performed in part by resident under direction of teaching physician (informational only)",
	GE: "Service performed by a resident without the presence of a teaching physician [primary care exception] (informational only)",
	KX: "Specific required documentation on file",
	EY: "No physician or other licensed health care provider order for this item or service",
	GA: "Waiver of liability statement (ABN) on file with ABN waiver signed",
	GY: "Item or service statutorily non-covered; No need to get ABN waiver",
	GZ: "Item or service expected to be denied as not reasonable and necessary",
	A1: "Dressing for one wound",
	A2: "Dressing for two wounds",
	A3: "Dressing for three wounds",
	A4: "Dressing for four wounds",
	A5: "Dressing for five wounds",
	A6: "Dressing for six wounds",
	A7: "Dressing for seven wounds",
	A8: "Dressing for eight wounds",
	A9: "Dressing for nine or more wounds",
};
