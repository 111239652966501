import React from "react";
import { Box } from "@mui/material";
import Navbar from "./Navbar";

interface CommonLayoutProps {
  children: React.ReactNode;
}

const CommonLayout: React.FC<CommonLayoutProps> = ({ children }) => {
  return (
    <Box>
      <Navbar />
      <Box className="containerBackground">{children}</Box>
    </Box>
  );
};

export default CommonLayout;
