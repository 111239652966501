import React from "react";
import {
  AppBar,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { AlgoliaSearch } from "../hooks/algoliaSearch";
import { Link } from "react-router-dom";
import LogoutButton from "./LogoutButton";
import { useAuth } from "../middlewares/AuthContext";
import { adminAccess } from "../constants";
import AccountCircle from "@mui/icons-material/AccountCircle";

const Navbar: React.FC = () => {
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const allowAccess = (requiredRole: string) => {
    return (
      user?.permissions.indexOf(requiredRole) === -1 &&
      user?.permissions.indexOf(adminAccess) === -1
    );
  };
  return (
    <AppBar className="appBar" elevation={2}>
      <Toolbar>
        <Typography
          variant="h6"
          fontWeight={"600"}
          color={"blue"}
          sx={{ marginRight: 3 }}
        >
          CS
        </Typography>
        <Button color="inherit" component={Link} to="/home">
          Dashboard
        </Button>
        {user?.role_name === "admin" && (
          <Button color="inherit" component={Link} to="/upload-files">
            Uploads
          </Button>
        )}
        {!allowAccess("add_coder") && (
          <Button color="inherit" component={Link} to="/rules">
            Rules
          </Button>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 1 }}>
          <AlgoliaSearch
            onResultClick={function (hit: any): void {
              throw new Error("Function not implemented.");
            }}
          />
        </Box>
        {user?.role_name === "admin" && (
          <>
            <Button color="inherit" onClick={handleMenuOpen}>
              <AccountCircle />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {!allowAccess("add_users") && (
                <MenuItem
                  onClick={handleMenuClose}
                  component={Link}
                  to="/users"
                >
                  Users
                </MenuItem>
              )}
              {!allowAccess("add_roles") && (
                <MenuItem
                  onClick={handleMenuClose}
                  component={Link}
                  to="/roles"
                >
                  Roles
                </MenuItem>
              )}
            </Menu>
          </>
        )}
        <LogoutButton />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
