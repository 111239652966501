"use client";
import MicIcon from "@mui/icons-material/Mic";
import ReplayIcon from "@mui/icons-material/Replay";
import StopIcon from "@mui/icons-material/Stop";
import { Box, Button, LinearProgress, ThemeProvider, Typography, createTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f44336", // Red color
    },
  },
});

interface SimpleRecordButtonProps {
  onTranscriptionComplete: (transcribedText: string) => void;
  onAudioRecorded: (audioBlob: Blob) => void;
}

const SimpleRecordButton: React.FC<SimpleRecordButtonProps> = ({ onTranscriptionComplete, onAudioRecorded }) => {
	const [isRecording, setIsRecording] = useState<boolean>(false);
	const [audioStream, setAudioStream] = useState<MediaStream | null>(null);
	const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
	const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
	const [recordingTime, setRecordingTime] = useState<number>(0);
	const timerRef = useRef<NodeJS.Timeout | null>(null);
	const RECORDING_MAX_DURATION = 240; // 4 minutes in seconds

  useEffect(() => {
    if (audioBlob) {
      onAudioRecorded(audioBlob);
    }
  }, [audioBlob, onAudioRecorded]);

	useEffect(() => {
		if (!audioStream) {
			navigator.mediaDevices
				.getUserMedia({ audio: true })
				.then((stream) => {
					setAudioStream(stream);
					const recorder = new MediaRecorder(stream);
					setMediaRecorder(recorder);
					let audio: Blob[];

					recorder.ondataavailable = (event: BlobEvent) => {
						if (event.data.size > 0) {
							audio = [event.data];
						}
					};

					recorder.onstop = () => {
						const b = new Blob(audio, { type: "audio/wav" });
						setAudioBlob(b);
					};
				})
				.catch((error) => {
					console.error("Error accessing microphone:", error);
				});
		}

		return () => {
			if (timerRef.current) {
				clearInterval(timerRef.current);
			}
		};
	}, [audioStream]);

	const handleToggleRecording = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		if (isRecording) {
			stopRecording();
		} else {
			startRecording();
		}
	};

	const startRecording = () => {
		if (mediaRecorder) {
			mediaRecorder.start();
			setIsRecording(true);
			setRecordingTime(0);
			setAudioBlob(null);
			timerRef.current = setInterval(() => {
				setRecordingTime((prevTime) => {
					if (prevTime >= RECORDING_MAX_DURATION - 1) {
						stopRecording();
						return RECORDING_MAX_DURATION;
					}
					return prevTime + 1;
				});
			}, 1000);
		}
	};

	const stopRecording = () => {
		if (mediaRecorder) {
			mediaRecorder.stop();
			setIsRecording(false);
			if (timerRef.current) {
				clearInterval(timerRef.current);
			}
		}
	};

	const formatTime = (seconds: number): string => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;

		return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
	};

	return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={isRecording ? <StopIcon /> : audioBlob ? <ReplayIcon /> : <MicIcon />}
          onClick={handleToggleRecording}
        >
          {isRecording ? "Stop Recording" : audioBlob ? "Redo Recording" : "Start Recording"}
        </Button>

        {isRecording && (
          <Box sx={{ width: "100%", maxWidth: 300 }}>
            <Typography variant="body2" color="text.secondary">
              Recording... {formatTime(recordingTime)}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={(recordingTime / RECORDING_MAX_DURATION) * 100}
              sx={{ mt: 1 }}
            />
          </Box>
        )}

        {audioBlob && (
          <Box sx={{ mt: 2, textAlign: "center" }}>
            <Typography variant="subtitle1" gutterBottom>
              Preview recording before submitting:
            </Typography>
            <audio controls>
              <source src={URL.createObjectURL(audioBlob)} type="audio/wav" />
            </audio>
          </Box>
        )}
      </Box>
    </ThemeProvider>
	);
};

export default SimpleRecordButton;
