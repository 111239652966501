import React, { useState } from "react";
import { supabase } from "../services/supabase";
import { useAuth } from "../middlewares/AuthContext";
import { Navigate } from "react-router-dom";
import { Typography, TextField, Box, Button, Grid, Paper } from "@mui/material";
import { Snackbar, SnackbarCloseReason } from "@mui/joy";

function LoginPage() {
  const [email, setEmail] = useState("");
  const { login, isAuthenticated } = useAuth();
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setIsValidEmail(validateEmail(emailValue));
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const checkEmailExistence = async (email: string) => {
    const { data, error } = await supabase
      .from("allowed_emails")
      .select("id, email, role_id, roles(name,permissions)")
      .eq("email", email);

    if (error) {
      setOpen(true);
      setErrorMessage("Error checking email existence:" + error.message);
      return [];
    }

    return data.map((item: any) => ({
      id: item.id,
      email: item.email,
      role_id: item.role_id,
      role_name: item.roles.name,
      permissions: item.roles.permissions,
    }));
  };

  const handleLogin = async () => {
    try {
      const exists = await checkEmailExistence(email);
      if (exists.length > 0) {
        login(exists[0]);
      } else {
        setOpen(true);
        setErrorMessage(
          "You do not have access, Please reach out to the admin"
        );
      }
    } catch (error: any) {
      setOpen(true);
      setErrorMessage("Error logging in" + error.message);
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/home" replace />;
  }

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          backgroundImage: "url(/background.png)", // Use the correct path to your image
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 3,
        }}
      >
        <Box
          textAlign="center"
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            p: 2,
            borderRadius: 2,
          }}
        >
          <Typography
            component="h1"
            variant="h3"
            sx={{
              mb: 2,
              fontFamily: "Space Grotesk, sans-serif",
              fontWeight: "bold",
              color: "#166534",
              fontSize: "5rem",
            }}
          >
            CodeSync
          </Typography>
          <Typography
            component="h2"
            variant="h5"
            sx={{ color: "slategrey", fontSize: "1.3rem" }}
          >
            Autonomous Medical Coding Software
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        component={Paper}
        elevation={6}
        square
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#c1dcd7", // Apply the specified color outside the border
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 500,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            border: "1px solid #ccc", // Add border here
            padding: "24px 32px", // Increased top and bottom padding
            borderRadius: 2, // Optional: Add border radius
            backgroundColor: "#ffffff", // Keep the inside of the box white
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            sx={{ textAlign: "start", fontWeight: "bold", ml: 2 }}
          >
            Enter Email to continue
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{
              mt: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={handleEmailChange}
              error={!isValidEmail && email !== ""}
              helperText={
                !isValidEmail && email !== "" ? "Enter a valid email" : ""
              }
              sx={{
                width: "90%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "15px",
                  "& fieldset": {
                    borderColor: "#14a884",
                  },
                  "&:hover fieldset": {
                    borderColor: "#14a884",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#14a884",
                  },
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#14a884",
                },
              }}
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                width: "90%",
                backgroundColor: "#14a884",
                "&:hover": {
                  backgroundColor: "#128a6d", // A slightly darker shade for hover effect
                },
              }}
              onClick={handleLogin}
              disabled={!isValidEmail}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        open={open}
        variant={"solid"}
        onClose={(
          event: React.SyntheticEvent<Element, Event> | Event | null,
          reason: SnackbarCloseReason
        ) => {
          if (reason === "clickaway") {
            return;
          }
          setOpen(false);
        }}
      >
        {errorMessage}
      </Snackbar>
    </Grid>
  );
}

export default LoginPage;
