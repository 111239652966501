import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Drawer,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import type React from "react";
import { useEffect, useRef, useState } from "react";
import AlgoliaSearch from "../hooks/algoliaSearch";

interface AddCodeDrawerProps {
	open: boolean;
	onClose: () => void;
	onSubmit: (
		code: string,
		description: string,
		comment: string,
		source: string,
	) => void;
	existingCodes?: { code: string; description: string }[];
}

const AddCodeDrawer: React.FC<AddCodeDrawerProps> = ({
	open,
	onClose,
	onSubmit,
	// existingCodes,
}) => {
	const [code, setCode] = useState("");
	const [description, setDescription] = useState("");
	const [hierarchy, setHierarchy] = useState("");
	const [comment, setComment] = useState("");
	const [source, setSource] = useState("");
	const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
	const searchBoxRef = useRef<HTMLDivElement>(null);

	const handleResultClick = (hit: any) => {
		const newCode = hit.extension_2 || hit.code || "N/A";
		const newDescription = hit.block || hit.description || "N/A";

		const hierarchyParts = [
			hit.chapter,
			hit.block,
			hit.category,
			hit.sub_category,
			hit.extension_1,
			hit.extension_2,
		].filter(Boolean); // Remove undefined or null values

		const hierarchy = hierarchyParts.join(" > ");
		setHierarchy(hierarchy);

		setCode(newCode);
		setDescription(newDescription);
		setSource(hit.extension_2 ? "extension_2" : "code");
	};

	const handleClose = () => {
		setCode("");
		setDescription("");
		setHierarchy("");
		setComment("");
		setSource("");
		onClose();
	};

	const handleDuplicateDialogClose = () => {
		setDuplicateDialogOpen(false);
	};

	const handleSubmit = () => {
		// const simplifiedCode = code.split(".")[0];
		// const isDuplicate = existingCodes.some(
		//   (item) => item.code === simplifiedCode
		// );
		const isDuplicate = false;

		if (isDuplicate) {
			setDuplicateDialogOpen(true);
		} else {
			onSubmit(code, description, comment, source);
			handleClose();
		}
	};

	useEffect(() => {
		if (!open) {
			setCode("");
			setDescription("");
			setHierarchy("");
			setComment("");
			setSource("");
		}
	}, [open]);

	return (
		<>
			<Drawer
				anchor="right"
				open={open}
				onClose={handleClose}
				PaperProps={{
					sx: { width: "28vw", display: "flex", flexDirection: "column" },
				}}
			>
				<Box className="drawer-header">
					<Typography variant="h6">Add Code</Typography>
					<IconButton onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</Box>
				<Box className="drawer-content">
					<Box className="drawer-box">
						<Box className="drawer-box-item">
							<Typography variant="body1" className="font-bold color-gray">
								Search Code
							</Typography>
							<Box sx={{ flexGrow: 1 }} ref={searchBoxRef}>
								<AlgoliaSearch onResultClick={handleResultClick} />
								<div className="custom-search-results"></div>
							</Box>
						</Box>
						<Box className="drawer-field">
							<Typography
								variant="subtitle2"
								gutterBottom
								className="font-bold color-gray"
							>
								Code
							</Typography>
							<Typography variant="body2" className="color-text-secondary">
								{code}
							</Typography>
						</Box>
						<Box className="drawer-field">
							<Typography
								variant="subtitle2"
								gutterBottom
								className="font-bold color-gray"
							>
								Description
							</Typography>
							<Typography variant="body2" className="drawer-field-content">
								{description}
							</Typography>
						</Box>
						{hierarchy && (
							<Box
								sx={{
									borderRadius: "4px",
									padding: "8px",
									width: "90%",
									flexGrow: 1,
									display: "flex",
									flexDirection: "column",
									height: "100%",
								}}
							>
								<>
									<Typography
										variant="subtitle2"
										gutterBottom
										sx={{ fontWeight: "bold", color: "gray" }}
									>
										Hierarchy
									</Typography>
									<Typography
										variant="body2"
										sx={{
											flexGrow: 1,
											overflowY: "auto",
										}}
									>
										{hierarchy}
									</Typography>
								</>
							</Box>
						)}
						<Box
							sx={{
								borderRadius: "4px",
								padding: "8px",
								width: "90%",
								flexGrow: 1,
								display: "flex",
								flexDirection: "column",
								height: "100%",
							}}
						>
							<Typography
								variant="subtitle1"
								gutterBottom
								className="font-bold color-gray"
							>
								Comment
							</Typography>
							<TextField
								multiline
								rows={4}
								value={comment}
								onChange={(e: any) => setComment(e.target.value)}
								fullWidth
							/>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						padding: 2,
						borderTop: "1px solid #ccc",
					}}
				>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>
					<Button onClick={handleSubmit} color="primary" variant="contained">
						Submit
					</Button>
				</Box>
			</Drawer>
			<Dialog open={duplicateDialogOpen} onClose={handleDuplicateDialogClose}>
				<DialogTitle>Duplicate Entry</DialogTitle>
				<DialogContent>
					<DialogContentText>
						This data is already present in the chart.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDuplicateDialogClose}>OK</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default AddCodeDrawer;
