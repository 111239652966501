import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, CircularProgress, Paper } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useEffect, useState } from "react";
//@ts-ignore
import FileViewer from "react-file-viewer";
import Markdown from "react-markdown";
import { isObjectEmpty } from "../../helpers";
import ErrorComponent from "../ErrorComponent";
import { ViewFileAccordionProps } from "./types";

const ViewFileAccordian: React.FC<ViewFileAccordionProps> = ({
	file,
	handleDelete,
	openChart,
}) => {
	const [fileType, setFileType] = useState<string | null>(null);
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		setFileType(file.name.split(".").pop()?.toLowerCase() || "");
	}, [file]);

	const handleExpand = () => {
		setExpanded((prev) => !prev);
	};

	const DisplayFileViewer = (file: any) => {
		if (isObjectEmpty(file)) return null;
		const filePath = URL.createObjectURL(file.file);
		return (
			<Paper
				variant="outlined"
				style={{
					width: "65%",
					maxHeight: "90vh",
					overflow: "scroll",
				}}
			>
				<FileViewer
					fileType={fileType}
					filePath={filePath}
					errorComponent={<p>Error loading file</p>}
					onError={(error: Error) => {
						<ErrorComponent error={error.message} show={true} />;
					}}
				/>
			</Paper>
		);
	};

	return (
		<div style={{ width: "100%" }}>
			<Accordion defaultExpanded onChange={handleExpand}>
				<AccordionSummary
					sx={{
						"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
							transform: "rotate(0deg)",
							transition: "transform 0.3s ease",
						},
					}}
					expandIcon={
						<div style={{ display: "flex", alignItems: "center" }}>
							{file.status === "pending" ? (
								<div>
									<Button onClick={() => handleDelete(file?.id)}>
										<DeleteIcon sx={{ color: "#ED2939" }} />
									</Button>
								</div>
							) : file.status === "complete" ? (
								<Button
									onClick={(event: React.MouseEvent<Element, MouseEvent>) =>
										file.chartId && openChart(file.chartId, event)
									}
									onAuxClick={(event: React.MouseEvent<Element, MouseEvent>) =>
										file.chartId && openChart(file.chartId, event)
									}
									disabled={!file?.chartId}
								>
									View Chart
								</Button>
							) : null}

							<ExpandMoreIcon
								style={{
									transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
									transition: "transform 0.3s ease",
								}}
							/>
						</div>
					}
					aria-controls="panel3-content"
					id="panel3-header"
				>
					{file?.name}
					{file.status === "processing" && (
						<CircularProgress size={16} sx={{ ml: 1 }} />
					)}
					{file.status === "complete" && ` (Chart ID: ${file.chartId})`}
					{file.status === "error" && (
						<ErrorComponent
							error="Error occurred while uploading"
							show={true}
						/>
					)}
				</AccordionSummary>
				<AccordionDetails
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						width: "100%",
					}}
				>
					{fileType === "md" || fileType === "docx" ? (
						<Box sx={{ width: "60vw", maxHeight: "90vh", overflow: "scroll" }}>
							<Markdown>{file?.content}</Markdown>
						</Box>
					) : (
						<DisplayFileViewer file={file?.file} />
					)}
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

export default ViewFileAccordian;
