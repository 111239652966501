import CodeIcon from "@mui/icons-material/Code";
import DeleteIcon from "@mui/icons-material/Delete";

import { Box, Grid, IconButton, Tooltip } from "@mui/material";

import { Typography } from "@mui/joy";

import {
	DndContext,
	type DragEndEvent,
	useDraggable,
	useDroppable,
} from "@dnd-kit/core";
import { useEffect, useState } from "react";
import { updateEncounterData } from "../helpers";
import CommentButtonWithTooltip from "./CommentsButtonWithTooltip";
import ErrorComponent from "./ErrorComponent";
import { HoverContainer } from "./HoverContainer";

interface CodeData {
	icd_codes_v2: {
		[key: string]: {
			tag?: string;
			label: string;
			for: string;
			highlight?: string;
		};
	};
	icd_codes_v3: [
		{
			tag: string | null;
			code: string;
			description: string;
			justification: string;
			highlight?: string;
			source?: string;
			status?: string;
		},
	];
	cpt_codes_v3: [
		{
			code: string;
			description: string;
			justification: string;
			emFlag?: boolean;
		},
	];
	cpt_codes_v2: {
		[key: string]: { label: string; for: string; emFlag?: boolean };
	};
	type_of_visit: string;
	status: string;
	raw_chart: string;
}

interface ErrorMessageType {
	message: string;
	type?: string;
}

interface ICDCode {
	tag: string | null;
	code: string;
	description: string;
	justification: string;
	highlight?: string;
	source?: string;
	status?: string;
}

interface ICDComponentProps {
	codeData: CodeData;
	customStyles: React.CSSProperties;
	rulesData: { [key: string]: string };
	comments: { [key: string]: any[] };
	handleHighlighting: (highlight: string) => void;
	toggleCommentDrawer: (open: boolean, code: string, type: string) => void;
	handleOpenDeleteDialog: (code: string, type: string) => void;
	handleOpenDrawer: (code: string) => void;
	HighlightedText: React.ComponentType<{ text: string; highlight?: string }>;
	patientId: string;
}

export const ICDComponentWrapper: React.FC<ICDComponentProps> = (props) => {
	return <>{ICDComponent(props)}</>;
};

const ICDComponent = (props: ICDComponentProps) => {
	const {
		codeData,
		customStyles,
		rulesData,
		comments,
		handleHighlighting,
		toggleCommentDrawer,
		handleOpenDeleteDialog,
		handleOpenDrawer,
		HighlightedText,
		patientId,
	} = props;

	const [icdCodes, setIcdCodes] = useState<ICDCode[]>(codeData.icd_codes_v3);
	const [errorMessage, setErrorMessage] = useState<ErrorMessageType | null>(
		null,
	);
	const [unsetTags, setUnsetTags] = useState<string[]>([]);

	useEffect(() => {
		const existingTags = new Set(
			icdCodes.map((code) => code.tag).filter(Boolean),
		);
		const allTags = ["primary", "secondary", "tertiary"];
		const missingTags = allTags.filter((tag) => !existingTags.has(tag));
		setUnsetTags(missingTags);
	}, [icdCodes]);

	const updateTags = async (updatedICDData: ICDCode[]) => {
		try {
			const { error } = await updateEncounterData(patientId, {
				//@ts-ignore
				icd_codes_v3: updatedICDData,
			});

			if (error) {
				throw new Error(error.message);
			}
			setErrorMessage(null);
		} catch (error) {
			setErrorMessage({
				message: `Error updating units: ${(error as Error).message}`,
				type: "error",
			});
		}
	};

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;

		if (over && active.id !== over.id) {
			setIcdCodes((prevCodes) => {
				const newCodes = [...prevCodes];
				const draggedTag = active.id as string;
				const droppedCodeIndex = newCodes.findIndex(
					(code) => code.code === over.id,
				);

				if (droppedCodeIndex !== -1) {
					const oldTag = newCodes[droppedCodeIndex].tag;
					newCodes[droppedCodeIndex].tag = draggedTag;

					// Find the code that previously had the dragged tag
					const oldTagIndex = newCodes.findIndex(
						(code) => code.tag === draggedTag && code.code !== over.id,
					);
					if (oldTagIndex !== -1) {
						newCodes[oldTagIndex].tag = null;
					}

					// Update unsetTags
					setUnsetTags((prevUnsetTags) => {
						const updatedUnsetTags = prevUnsetTags.filter(
							(tag) => tag !== draggedTag,
						);
						if (oldTag && !newCodes.some((code) => code.tag === oldTag)) {
							updatedUnsetTags.push(oldTag);
						}
						return updatedUnsetTags;
					});
				}
				updateTags(newCodes);
				return newCodes;
			});
		}
	};

	return (
		<Box>
			<DndContext onDragEnd={handleDragEnd}>
				{unsetTags.length > 0 && (
					<Box sx={{ marginBottom: 1 }}>
						<Typography
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "end",
								gap: 2,
							}}
						>
							<span style={{ fontWeight: "bold" }}>UNSET TAGS:</span>
							{unsetTags.map((tag) => (
								<DraggableTag key={tag} tag={tag} />
							))}
						</Typography>
					</Box>
				)}

				<ErrorComponent
					error={errorMessage?.message || ""}
					show={!!errorMessage?.message}
					onClose={() => setErrorMessage(null)}
				/>
				{icdCodes.map((line, index) => (
					<DraggableICDCode
						key={line.code}
						line={line}
						index={index}
						customStyles={customStyles}
						rulesData={rulesData}
						comments={comments}
						handleHighlighting={handleHighlighting}
						toggleCommentDrawer={toggleCommentDrawer}
						handleOpenDeleteDialog={handleOpenDeleteDialog}
						handleOpenDrawer={handleOpenDrawer}
						// @ts-ignore
						HighlightedText={HighlightedText}
					/>
				))}
			</DndContext>
		</Box>
	);
};

const DraggableTag = ({ tag }: { tag: string }) => {
	const { attributes, listeners, setNodeRef, transform } = useDraggable({
		id: tag,
	});
	const style = transform
		? {
				transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
				cursor: "grab",
			}
		: { cursor: "grab" };

	return (
		<span
			ref={setNodeRef}
			{...listeners}
			{...attributes}
			className={`${tag}-color`}
			style={{
				...style,
				marginRight: "30px",
			}}
		>
			{tag}
		</span>
	);
};

interface DraggableICDCodeProps {
	line: ICDCode;
	index: number;
	customStyles: React.CSSProperties;
	rulesData: { [key: string]: string };
	comments: { [key: string]: any[] };
	handleHighlighting: (highlight: string) => void;
	toggleCommentDrawer: (open: boolean, code: string, type: string) => void;
	handleOpenDeleteDialog: (code: string, type: string) => void;
	handleOpenDrawer: (code: string) => void;
	HighlightedText: React.ComponentType<{ text: string; highlight: string }>;
}

const DraggableICDCode: React.FC<DraggableICDCodeProps> = ({
	line,
	customStyles,
	rulesData,
	comments,
	handleHighlighting,
	toggleCommentDrawer,
	handleOpenDeleteDialog,
	handleOpenDrawer,
	HighlightedText,
}) => {
	const { setNodeRef: setDroppableRef, isOver } = useDroppable({
		id: line.code,
	});

	const dropStyle = {
		backgroundColor: isOver ? "rgba(0, 0, 0, 0.1)" : "transparent",
		transition: "background-color 0.2s ease",
		padding: "8px",
		borderRadius: "4px",
	};

	return (
		<Grid
			container
			className="margin-bottom-1"
			ref={setDroppableRef}
			style={dropStyle}
		>
			<Grid item xs={2}>
				<Grid container direction="column">
					<Grid
						item
						mt={1}
						style={{
							...customStyles,
							textDecoration: "underline",
							cursor: "pointer",
							marginBottom: 4,
						}}
						onClick={() => handleHighlighting(line?.highlight || "")}
					>
						{line?.code}
					</Grid>
					{line?.tag && <DraggableTag tag={line.tag} />}
				</Grid>
			</Grid>
			<Grid item xs={7}>
				<Typography style={customStyles}>
					{rulesData[line.code] ? (
						line.highlight ? (
							<>
								<Typography
									style={customStyles}
								>{` ${rulesData[line.code]}`}</Typography>
							</>
						) : (
							rulesData[line.code]
						)
					) : line.highlight ? (
						<HighlightedText
							text={line.justification}
							highlight={line.highlight}
						/>
					) : (
						line.justification
					)}
				</Typography>
			</Grid>
			<Grid item xs={3} justifyContent="flex-end">
				<HoverContainer>
					<Box display="flex" alignItems="end">
						<CommentButtonWithTooltip
							commentsCount={comments[line.code]?.length || 0}
							onClick={() => toggleCommentDrawer(true, line.code, "ICD")}
						/>
						<Tooltip title="Delete">
							<IconButton
								className="deleteButton"
								color="secondary"
								size="small"
								onClick={() => handleOpenDeleteDialog(line.code, "icd")}
								sx={{
									visibility: "hidden",
									color: "slategray",
									marginLeft: 0.5,
								}}
							>
								<DeleteIcon fontSize="small" />
							</IconButton>
						</Tooltip>
						<Tooltip title="rules">
							<IconButton
								className="inclusionButton"
								color="secondary"
								size="small"
								onClick={() => handleOpenDrawer(line.code)}
								sx={{
									visibility: "hidden",
									color: "slategray",
									marginLeft: 0.5,
								}}
							>
								<CodeIcon fontSize="small" />
							</IconButton>
						</Tooltip>
					</Box>
				</HoverContainer>
			</Grid>
		</Grid>
	);
};
