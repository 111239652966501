import { Snackbar, Typography } from "@mui/material";
import type { SnackbarCloseReason } from "@mui/material";
import { useEffect, useState } from "react";

interface ErrorComponentProps {
	error: string;
	show: boolean;
	type?: "success" | "info" | "error";
	onClose?: () => void;
}

const ErrorComponent = ({
	error,
	show,
	type = "error",
	onClose,
}: ErrorComponentProps) => {
	const [open, setOpen] = useState<boolean>(show || false);

	useEffect(() => {
		setOpen(show);
	}, [show]);

	return (
		<Snackbar
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			autoHideDuration={5000}
			open={open}
			onClose={(
				event: React.SyntheticEvent<Element, Event> | Event | null,
				reason: SnackbarCloseReason,
			) => {
				if (reason === "clickaway") {
					return;
				}
				setOpen(false);
				onClose?.();
			}}
			sx={{
				backgroundColor:
					type === "success"
						? "#2E7D32"
						: type === "info"
							? "#0288D1"
							: "#D32F2F",
				padding: 1,
				borderRadius: 0.5,
				color: type === "info" ? "black" : "white",
			}}
		>
			<Typography>{error}</Typography>
		</Snackbar>
	);
};

export default ErrorComponent;
