import CodeIcon from "@mui/icons-material/Code";
import DeleteIcon from "@mui/icons-material/Delete";

import { Box, Grid, IconButton, Chip, Tooltip } from "@mui/material";

import CommentButtonWithTooltip from "./CommentsButtonWithTooltip";
import { HoverContainer } from "./HoverContainer";
import { modifiers } from "../constants";
import { CPTCode } from "./PatientDetails/types";

interface CodeData {
	icd_codes_v2: {
		[key: string]: {
			tag?: string;
			label: string;
			for: string;
			highlight?: string;
		};
	};
	icd_codes_v3: [
		{
			tag: string;
			code: string;
			description: string;
			justification: string;
			highlight?: string;
		},
	];
	cpt_codes_v3: [
		{
			code: string;
			description: string;
			justification: string;
			emFlag?: boolean;
		},
	];
	cpt_codes_v2: {
		[key: string]: { label: string; for: string; emFlag?: boolean };
	};
	type_of_visit: string;
	status: string;
	raw_chart: string;
}

export const CPTComponentWrapper: React.FC<{
	codeData: CodeData;
	customStyles: any;
	rulesData: any;
	comments: any;
	handleHighlighting: any;
	toggleCommentDrawer: any;
	handleOpenDeleteDialog: any;
	handleOpenDrawer: any;
	HighlightedText: any;
}> = (props) => {
	return <>{CPTComponent(props)}</>;
};

const CPTComponent = (props: any) => {
	const {
		codeData,
		customStyles,
		rulesData,
		comments,
		toggleCommentDrawer,
		handleOpenDeleteDialog,
		handleOpenDrawer,
	} = props;

	const activeCptCodes = codeData.cpt_codes_v3.filter(
		(code: CPTCode) => code.status !== "deleted",
	);

	return activeCptCodes.map(
		(
			line: {
				code: string;
				description: string;
				justification: string;
				emFlag?: boolean;
				modifiers?: string[];
				source?: string;
				status?: string;
			},
			index: number,
		) => (
			<Grid container className="margin-bottom-1" key={index}>
				<Grid item xs={2} sx={{ padding: 1 }}>
					<Grid container direction="column">
						<Grid item mt={1}>
							<span className="badgeColor">{line.code}</span>
							{line.emFlag && (
								<Grid item mt={1}>
									<span className="em-color">E/M</span>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={8}>
					<span style={customStyles}>
						{rulesData[line.code]
							? rulesData[line.code]
							: line.description + " " + line.justification}
					</span>
					<Box
						sx={{
							display: "flex",
							flexWrap: "wrap",
							gap: 0.5,
							alignItems: "center",
						}}
					>
						{line.modifiers?.map((modifier, idx) => (
							<Tooltip
								key={idx}
								title={
									(modifiers as Record<string, string>)[modifier] || modifier
								}
								arrow
							>
								<Chip
									label={modifier}
									size="small"
									sx={{ marginRight: 0.5, marginBottom: 0.5 }}
								/>
							</Tooltip>
						))}
					</Box>
				</Grid>
				<Grid item xs={2} justifyContent="flex-end">
					<HoverContainer>
						<Box display="flex" alignItems="end">
							<CommentButtonWithTooltip
								commentsCount={comments[line.justification]?.length || 0}
								onClick={() => toggleCommentDrawer(true, line.code, "CPT")}
							/>
							<IconButton
								className="deleteButton"
								color="secondary"
								size="small"
								onClick={() => handleOpenDeleteDialog(line.code, "cpt")}
								sx={{
									visibility: "hidden",
									color: "slategray",
									marginLeft: 0.5,
								}}
							>
								<DeleteIcon fontSize="small" />
							</IconButton>

							<IconButton
								className="inclusionButton"
								color="secondary"
								size="small"
								onClick={() => handleOpenDrawer(line.code)}
								sx={{
									visibility: "hidden",
									color: "slategray",
									marginLeft: 0.5,
								}}
							>
								<CodeIcon fontSize="small" />
							</IconButton>
						</Box>
					</HoverContainer>
				</Grid>
			</Grid>
		),
	);
};
