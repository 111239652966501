import React, { useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, ListItemIcon } from "@mui/material";

interface Comment {
  comment_id: string;
  author: string;
  text: string;
  edited?: boolean;
}

interface CommentDrawerProps {
  open: boolean;
  toggleDrawer: (open: boolean) => void;
  comments: Comment[];
  draftComment: string;
  handleCommentChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    code: string
  ) => void;
  handleAddComment: (code: string, author: string) => void;
  handleUpdateComment: (code: string, author: string) => void;
  codeType: string;
  code: string;
  handleEditComment: (code: string, index: number) => void;
  handleDeleteComment: (code: string, index: number) => void;
  isEditing: boolean;
}

const CommentDrawer: React.FC<CommentDrawerProps> = ({
  open,
  toggleDrawer,
  comments,
  draftComment,
  handleCommentChange,
  handleAddComment,
  handleUpdateComment,
  codeType,
  code,
  handleEditComment,
  handleDeleteComment,
  isEditing,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCommentIndex, setSelectedCommentIndex] = useState<
    number | null
  >(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedCommentIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    if (selectedCommentIndex !== null) {
      handleEditComment(code, selectedCommentIndex);
    }
    handleMenuClose();
  };

  const handleConfirmDelete = () => {
    if (selectedCommentIndex !== null) {
      handleDeleteComment(code, selectedCommentIndex);
    }
    setDialogOpen(false);
    setSelectedCommentIndex(null);
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
    >
      <Box sx={{ width: "25vw", padding: 2 }}>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          {code}
        </Typography>
        <Typography variant="subtitle1" sx={{ marginBottom: 2, color: "gray" }}>
          {codeType}
        </Typography>
        {comments.length > 0 ? (
          comments.map((comment, index) => (
            <Box
              key={index}
              sx={{
                marginBottom: 2,
                padding: 1,
                border: "1px solid #ccc",
                borderRadius: 1,
                position: "relative",
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {comment.author}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "slategray", marginBottom: 1 }}
              >
                {comment.text}
              </Typography>
              {comment.edited && (
                <Typography
                  variant="caption"
                  sx={{
                    color: "slategray",
                    position: "absolute",
                    bottom: 2,
                    right: 2,
                  }}
                >
                  edited
                </Typography>
              )}
              <IconButton
                aria-label="more"
                aria-controls="comment-menu"
                aria-haspopup="true"
                onClick={(event: any) => handleMenuClick(event, index)}
                sx={{ position: "absolute", top: 2, right: 2 }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="comment-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleEditClick}>
                  <ListItemIcon>
                    <EditOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setDialogOpen(true);
                    handleMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <DeleteOutlineOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          ))
        ) : (
          <Typography
            variant="body2"
            sx={{ color: "slategray", marginBottom: 2 }}
          >
            No comments yet.
          </Typography>
        )}
        <Divider />
        <Typography variant="body2" sx={{ marginBottom: 2, marginTop: 2 }}>
          {isEditing ? "Edit Comment" : "Add Comment"}
        </Typography>
        <TextField
          label="Comment"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={draftComment}
          onChange={(event: any) => handleCommentChange(event, code)}
          sx={{ marginBottom: 2 }}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {isEditing ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleUpdateComment(code, "Current User")}
            >
              Update
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAddComment(code, "Current User")}
            >
              Submit
            </Button>
          )}
        </Box>
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Comment"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this comment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="secondary">
            No
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="primary"
            
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </SwipeableDrawer>
  );
};

export default CommentDrawer;
