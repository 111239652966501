import { createClient } from "@supabase/supabase-js";

import dotenv from "dotenv";

dotenv.config();
const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY;

if (!SUPABASE_URL || !SUPABASE_KEY) {
	throw new Error("Missing SUPABASE_URL or SUPABASE_KEY environment variables");
}

export const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);
