/* Import React libraries */
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

/* Material Import UI */
import { CircularProgress } from "@mui/joy";
import { Box, Grid } from "@mui/material";

/* Import internal components */
import BasicTable from "../components/TableComponent";
import { useAuth } from "../middlewares/AuthContext";

import { getEncounterData } from "../helpers";

/* Import constants */
import { listTableColumns } from "../constants";

function ItemListing() {
	const { isAuthenticated } = useAuth();
	const [codeList, setCodeList] = useState<any>([]);
	const [loader, setLoader] = useState<boolean>(true);
	// TODO: revisit
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [errorMessage, setErrorMessage] = useState<string>("");

	useEffect(() => {
		const loadData = async () => {
			try {
				const response = await getEncounterData();
				setCodeList(response);
			} catch (error: any) {
				setErrorMessage(error.message);
			} finally {
				setLoader(false);
			}
		};

		loadData();
	}, []);

	if (!isAuthenticated) {
		return <Navigate to="/" replace />;
	}

	return (
		<Grid flex={"row"}>
			{loader ? (
				<Box className="loaderBox">
					<CircularProgress variant="solid" />
				</Box>
			) : (
				<BasicTable data={codeList?.data} keys={listTableColumns} />
			)}
		</Grid>
	);
}

export default ItemListing;
