import Markdown from "react-markdown";

interface HighlightProps {
	node: any;
	highlightString: string;
}

function applyBackgroundColor(text: string, searchString: string): string {
	if (searchString) {
		const chars = searchString.split("");

		const patterns = chars.map((char) => {
			if (/[\d\W]/.test(char)) {
				return `\\${char}`;
			}
			return `[${char.toUpperCase()}${char.toLowerCase()}]`;
		});

		const pattern = patterns.join("");

		const regex = new RegExp(`(${pattern})`, "gi");

		const uniqueId = `scrollHighlight_${searchString}`;
		setTimeout(() => {
			const targetDiv = document.getElementById(uniqueId);
			targetDiv?.scrollIntoView({ behavior: "smooth", block: "center" });
		}, 0);
		return text.replace(
			regex,
			`<span id="${uniqueId}" style="background-color: yellow;">$&</span>`,
		);
	}
	return text;
}

const Highlight: React.FC<HighlightProps> = ({ node, highlightString }) => {
	if (!node || !node.children) {
		return null;
	}
	// Collect all text nodes
	const textContent = node.children
		.filter(
			(child: any) => !(child.type === "text" && child.value.trim() === ""),
		)
		.map((child: any) => {
			if (child.type === "text") {
				return child.value;
			}
			if (child.children) {
				return child.children
					.filter(
						(subChild: any) =>
							!(subChild.type === "text" && subChild.value.trim() === ""),
					)
					.map((subChild: any) => subChild.value)
					.join("");
			}
			return "";
		})
		.join("");

	if (!textContent.trim()) {
		return null;
	}

	const highlightedText = applyBackgroundColor(textContent, highlightString);
	return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
};

const List: React.FC<{
	node: any;
	ordered: boolean;
	highlightString: string;
}> = ({ node, ordered, highlightString }) => {
	const ListTag = ordered ? "ol" : "ul";
	return (
		<ListTag>
			{node.children
				.filter(
					(child: any) => child.type === "element" && child.tagName === "li",
				)
				.map((child: any, index: number) => (
					<li key={index}>
						<Highlight node={child} highlightString={highlightString} />
					</li>
				))}
		</ListTag>
	);
};

const CustomMarkdown: React.FC<{
	content: string;
	highlightString: string;
}> = ({ content, highlightString }) => {
	const renderers = {
		p: (props: any) => (
			<Highlight node={props.node} highlightString={highlightString} />
		),
		strong: (props: any) => (
			<Highlight node={props.node} highlightString={highlightString} />
		),
		li: ({ node, ...props }: any) => (
			<li {...props}>
				<Highlight node={node} highlightString={highlightString} />
			</li>
		),
		ol: ({ node, ...props }: any) => (
			<List node={node} ordered={true} highlightString={highlightString} />
		),
		ul: ({ node, ...props }: any) => (
			<List node={node} ordered={false} highlightString={highlightString} />
		),
		span: (props: any) => (
			<Highlight node={props.node} highlightString={highlightString} />
		),
		h1: ({ node, ...props }: any) => (
			<h1 {...props}>
				<Highlight node={node} highlightString={highlightString} />
			</h1>
		),
		h2: ({ node, ...props }: any) => (
			<h2 {...props}>
				<Highlight node={node} highlightString={highlightString} />
			</h2>
		),
		h3: ({ node, ...props }: any) => (
			<h3 {...props}>
				<Highlight node={node} highlightString={highlightString} />
			</h3>
		),
	};

	return <Markdown components={renderers}>{content}</Markdown>;
};

export default CustomMarkdown;
