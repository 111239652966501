import DownloadIcon from "@mui/icons-material/Download";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Chip,
	Box,
	Autocomplete,
	AutocompleteRenderOptionState,
	AutocompleteInputChangeReason,
} from "@mui/material";
import { useEffect, useState } from "react";
import { codeUnitsTableColumns, modifiers } from "../../constants";

import {
	mixpanelConfig,
	updateCoderSubmissionStatus,
	updateCoderSavedUnits,
	updateQASubmissionStatus,
} from "../../helpers";
import ErrorComponent from "../ErrorComponent";
import type { UpdateUnitsDialogProps, CodeUnit } from "./types";
import { useAuth } from "../../middlewares/AuthContext";

const UpdateUnitsDialog: React.FC<UpdateUnitsDialogProps> = ({
	onClose,
	open,
	exportData,
	cptCodesV3,
	updatedData,
	setUpdatedData,
	isSubmitDisabled,
	setIsSubmitDisabled,
	errorMessage,
	setErrorMessage,
	updateUnits,
	chartId,
	coderSubmitted,
	hasQASubmitted,
	coderSavedUnits = [],
}) => {
	const [isDownloadable, setIsDownloadable] = useState(false);
	const storedUser = localStorage.getItem("user");
	const email = storedUser ? JSON.parse(storedUser).email : null;
	const mixpanel = mixpanelConfig(email);

	const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
	const { user } = useAuth();
	const role = user?.role_name;

	const isAdmin = role === "admin";
	const isCoder = role === "coder";
	const isQA = role === "qa";

	const allCodesHaveSavedUnits = cptCodesV3.every((code) =>
		coderSavedUnits.some((savedUnit) => savedUnit.code === code.code),
	);

	const canEdit = (isCoder && !allCodesHaveSavedUnits) || isQA;

	useEffect(() => {
		const newUpdatedData = exportData.map((item: CodeUnit) => {
			const matchingCptCode = cptCodesV3.find(
				(cptCode) => cptCode.code === item.code,
			);
			const coderSavedUnit = coderSavedUnits?.find(
				(savedUnit) => savedUnit.code === item.code,
			);
			return {
				...item,
				units: hasQASubmitted
					? matchingCptCode?.units
					: coderSubmitted && coderSavedUnit
						? coderSavedUnit.units
						: matchingCptCode?.units,
				modifiers: hasQASubmitted
					? matchingCptCode?.modifiers
					: coderSubmitted && coderSavedUnit
						? coderSavedUnit.modifiers
						: matchingCptCode?.modifiers || item.modifiers || [],
			};
		});
		setUpdatedData(newUpdatedData);

		const allFieldsFilled = newUpdatedData.every(
			(item) => item.units !== undefined && item.units !== null,
		);
		setIsSubmitDisabled(!canEdit || allCodesHaveSavedUnits);
		setIsDownloadable(allFieldsFilled);
	}, [
		cptCodesV3,
		exportData,
		setUpdatedData,
		canEdit,
		coderSubmitted,
		setIsSubmitDisabled,
		hasQASubmitted,
	]);

	const handleUnitChange = (code: string, value: number) => {
		if (!canEdit) return;
		const validValue = value < 0 ? 0 : value;
		mixpanel.track("Chart - Updated units", {
			chartId,
			code,
			unitsDefault: value,
			unitsPicked: validValue,
		});
		setUpdatedData((prevData) =>
			prevData.map((item) =>
				item.code === code ? { ...item, units: validValue } : item,
			),
		);
		setIsSubmitDisabled(false);
	};

	const handleModifierChange = (code: string, newModifier: string | null) => {
		if (!canEdit) return;
		if (newModifier) {
			setUpdatedData((prevData) =>
				prevData.map((item) =>
					item.code === code
						? {
								...item,
								modifiers: Array.from(
									new Set([...(item.modifiers || []), newModifier]),
								),
							}
						: item,
				),
			);
			setIsSubmitDisabled(false);
		}
	};

	const handleRemoveModifier = (code: string, modifierToRemove: string) => {
		if (!canEdit) return;
		setUpdatedData((prevData) =>
			prevData.map((item) =>
				item.code === code
					? {
							...item,
							modifiers: (item.modifiers || []).filter(
								(mod) => mod !== modifierToRemove,
							),
						}
					: item,
			),
		);
		setIsSubmitDisabled(false);
	};

	const handleSubmit = async () => {
		if (!canEdit) return;
		try {
			if (isCoder) {
				// Save units and modifiers to coder_saved_units inside cpt_codes_v3
				await updateUnits(updatedData);
				await updateCoderSubmissionStatus(chartId, true);
			} else if (isQA && coderSubmitted) {
				// Update actual units and modifiers
				const updatedCptCodesV3 = cptCodesV3.map((cptCode) => {
					const updatedUnit = updatedData.find(
						(item) => item.code === cptCode.code,
					);
					if (updatedUnit) {
						return {
							...cptCode,
							units: updatedUnit.units,
							modifiers: updatedUnit.modifiers,
						};
					}
					return cptCode;
				});
				await updateCoderSavedUnits(chartId, updatedCptCodesV3);
				await updateQASubmissionStatus(chartId, true);
			}

			setIsSubmitDisabled(true);
			onClose();
		} catch (error) {
			console.error("Error updating CPT codes:", error);
			setErrorMessage({
				message: "Failed to update CPT codes. Please try again.",
				type: "error",
			});
		}
	};

	const handleDownload = () => {
		if (!isDownloadable) return;
		const csvContent = `data:text/csv;charset=utf-8,Code Type,Code,Units,Modifiers\n${updatedData
			.map(
				(row) =>
					`${row.code_type},${row.code},${row.units ?? ""},${row.modifiers?.join("|") || ""}`,
			)
			.join("\n")}`;

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", `${chartId}_units.csv`);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

		mixpanel.track("Chart - Download units", {
			chartId,
		});
	};

	const handleInputChange = (code: string, newInputValue: string) => {
		setInputValues((prev) => ({ ...prev, [code]: newInputValue }));
	};

	return (
		<Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
			<DialogTitle>
				Codes and Units
				<Tooltip title="Export as CSV">
					<IconButton onClick={handleDownload} style={{ float: "right" }}>
						<DownloadIcon />
					</IconButton>
				</Tooltip>
			</DialogTitle>
			{isQA && !coderSubmitted && (
				<DialogContentText
					style={{
						color: "orange",
						fontSize: "small",
						marginLeft: 20,
					}}
				>
					⚠️ Warning: Coder hasn't submitted units yet.
				</DialogContentText>
			)}
			{isCoder && !coderSubmitted && (
				<DialogContentText
					style={{
						color: "blue",
						fontSize: "small",
						marginLeft: 20,
					}}
				>
					ℹ️ Units once submitted by coder can only be modified by QA.
				</DialogContentText>
			)}
			<DialogContent>
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								{codeUnitsTableColumns.map((col) => (
									<TableCell key={col.column} width={col.width}>
										{col.title}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{updatedData.map((row) => {
								const matchingCptCode = cptCodesV3.find(
									(cptCode) => cptCode.code === row.code,
								);
								const isEmFlag = matchingCptCode?.emFlag;
								const hasCoderSavedUnits = !!matchingCptCode?.coder_saved_units;
								const canEditFields = (isCoder && !hasCoderSavedUnits) || isQA;
								return (
									<TableRow key={row?.code}>
										{codeUnitsTableColumns.map((col) => (
											<TableCell key={`${row.code}-${col.column}`}>
												{col.column === "code_type" &&
													row.code_type.toUpperCase()}
												{col.column === "code" && row.code}
												{col.column === "units" && (
													<TextField
														sx={{
															"& .MuiInputBase-root.Mui-disabled": {
																backgroundColor: "#f0f0f0",
																color: "#888",
																pointerEvents: "none",
															},
															"& .MuiInputBase-input.Mui-disabled": {
																WebkitTextFillColor: "#888",
																cursor: "not-allowed",
															},
															width: "100%",
														}}
														type="number"
														value={row?.units}
														variant="outlined"
														size="small"
														onChange={(
															e: React.ChangeEvent<HTMLInputElement>,
														) =>
															handleUnitChange(
																row?.code,
																Number(e.target.value),
															)
														}
														disabled={isAdmin || isEmFlag || !canEditFields}
														inputProps={{ min: 0 }}
														fullWidth
													/>
												)}
												{col.column === "add_modifier" && (
													<Autocomplete
														sx={{
															"& .MuiInputBase-root.Mui-disabled": {
																backgroundColor: "#f0f0f0",
																color: "#888",
																pointerEvents: "none",
															},
															"& .MuiInputBase-input.Mui-disabled": {
																WebkitTextFillColor: "#888",
																cursor: "not-allowed",
															},
															width: "100%",
														}}
														options={Object.keys(modifiers)}
														renderInput={(params) => (
															<TextField
																{...params}
																variant="outlined"
																size="small"
																placeholder="Add"
															/>
														)}
														renderOption={(
															props: React.HTMLAttributes<HTMLLIElement>,
															option: string,
															state: AutocompleteRenderOptionState,
														) => (
															<Tooltip
																title={
																	modifiers[option as keyof typeof modifiers]
																}
																arrow
															>
																<li {...props}>{option}</li>
															</Tooltip>
														)}
														onChange={(
															event: React.SyntheticEvent,
															newValue: string | null,
														) => {
															if (newValue) {
																handleModifierChange(row.code, newValue);
																// Clear the input field after adding a modifier
																setTimeout(() => {
																	handleInputChange(row.code, "");
																}, 0);
															}
														}}
														value={null}
														inputValue={inputValues[row.code] || ""}
														onInputChange={(
															event: React.SyntheticEvent,
															newInputValue: string,
															reason: AutocompleteInputChangeReason,
														) => {
															handleInputChange(row.code, newInputValue);
														}}
														disabled={isAdmin || isEmFlag || !canEditFields}
														size="small"
														fullWidth
														freeSolo
														selectOnFocus
														clearOnBlur
														handleHomeEndKeys
													/>
												)}
												{col.column === "modifiers" && (
													<Box
														sx={{
															display: "flex",
															flexWrap: "wrap",
															gap: 0.5,
															width: "300px",
															maxWidth: "100%",
														}}
													>
														{row.modifiers?.map((modifier) => (
															<Tooltip
																key={modifier}
																title={
																	modifiers[
																		modifier as keyof typeof modifiers
																	] || ""
																}
																arrow
															>
																<Chip
																	label={modifier}
																	onDelete={() =>
																		handleRemoveModifier(row.code, modifier)
																	}
																	size="small"
																/>
															</Tooltip>
														))}
													</Box>
												)}
											</TableCell>
										))}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				{errorMessage && (
					<ErrorComponent
						error={errorMessage}
						show={true}
						type="success"
						onClose={() => setErrorMessage(null)}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="secondary">
					Cancel
				</Button>
				<Button
					onClick={handleSubmit}
					color="primary"
					disabled={
						isAdmin ||
						(isCoder &&
							updatedData.every(
								(row) =>
									!!cptCodesV3.find(
										(cptCode) => cptCode.code === row.code && !cptCode.emFlag,
									)?.coder_saved_units,
							)) ||
						isSubmitDisabled
					}
				>
					{isCoder && !coderSubmitted ? "Save" : "Submit"}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default UpdateUnitsDialog;
