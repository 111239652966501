// src/components/Users.tsx
import React, { useState, useEffect } from "react";
import { supabase } from "../services/supabase";
import {
  TextField,
  Button,
  Typography,
  IconButton,
  Autocomplete,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import { Snackbar, SnackbarCloseReason } from "@mui/joy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircle from "@mui/icons-material/AddCircle";

interface User {
  id: number;
  email: string;
  role_id: number;
}

interface Role {
  id: number;
  name: string;
}

const Users = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [newUser, setNewUser] = useState({ email: "", role_id: 0 });
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, []);

  const fetchUsers = async () => {
    const { data, error } = await supabase.from("allowed_emails").select("*");
    if (error) setErrorMessage(error.message);
    else setUsers(data);
  };

  const fetchRoles = async () => {
    const { data, error } = await supabase.from("roles").select("*");
    if (error) setErrorMessage(error.message);
    else setRoles(data);
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleAddUser = async () => {
    if (!validateEmail(newUser.email)) {
      setEmailError("Invalid email address");
      return;
    }

    if (editingUser) {
      const { data, error } = await supabase
        .from("allowed_emails")
        .update(newUser)
        .eq("id", editingUser.id)
        .select();
      if (error) setErrorMessage(error.message);
      else {
        setErrorMessage("Updated user successfully.");
        setUsers(
          users.map((user) => (user.id === editingUser.id ? data[0] : user))
        );
        setEditingUser(null);
      }
    } else {
      const { data, error } = await supabase
        .from("allowed_emails")
        .insert([newUser])
        .select();
      if (error) setErrorMessage(error.message);
      else {
        setErrorMessage("Added new user successfully.");
        setUsers([...users, ...data]);
      }
    }
    setNewUser({ email: "", role_id: 0 });
    setShowForm(false);
  };

  const handleDeleteUser = async (id: number) => {
    const { error } = await supabase
      .from("allowed_emails")
      .delete()
      .eq("id", id);
    if (error) setErrorMessage(error.message);
    else {
      setErrorMessage("Deleted user successfully.");
      setUsers(users.filter((user) => user.id !== id));
    }
  };

  const handleEditUser = (user: User) => {
    setNewUser({ email: user.email, role_id: user.role_id });
    setShowForm(true);
    setEditingUser(user);
  };

  return (
    <Grid container p={4} className="justify-center">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        open={!!errorMessage}
        variant={"solid"}
        onClose={(
          event: React.SyntheticEvent<Element, Event> | Event | null,
          reason: SnackbarCloseReason
        ) => {
          if (reason === "clickaway") {
            return;
          }
          setErrorMessage("");
        }}
      >
        {errorMessage}
      </Snackbar>
      <Typography variant="h4">Users Management</Typography>
      <Grid container className="items-center justify-center">
        <Grid item xs={6} p={4}>
          <Table>
            <TableHead>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {roles.find((role) => role.id === user.role_id)?.name ||
                      "Unknown"}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEditUser(user)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteUser(user.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            onClick={() => {
              setShowForm(true);
              setEditingUser(null);
              setNewUser({ email: "", role_id: 0 });
            }}
            variant="contained"
            color="primary"
            startIcon={<AddCircle />}
            sx={{ marginTop: "1rem" }}
          >
            Add User
          </Button>
        </Grid>

        {showForm && (
          <Grid item xs={6} p={4}>
            <Typography variant="body1" mb={2}>
              {editingUser ? "Update User" : "Add User"}
            </Typography>
            <TextField
              label="Email"
              value={newUser.email}
              onChange={(e: any) => {
                setNewUser({ ...newUser, email: e.target.value });
                setEmailError("");
              }}
              type="email"
              error={!!emailError}
              helperText={emailError}
              fullWidth
            />
            <Autocomplete
              options={roles}
              getOptionLabel={(option: Role) => option.name}
              value={roles.find((role) => role.id === newUser.role_id) || null}
              onChange={(event: any, newValue: Role | null) =>
                setNewUser({ ...newUser, role_id: newValue?.id || 0 })
              }
              renderInput={(params) => (
                <TextField {...params} label="Role" placeholder="Select role" />
              )}
              sx={{ margin: "1rem 0" }}
            />
            <Button onClick={() => setShowForm(false)} color="warning">
              Cancel
            </Button>
            <Button
              onClick={handleAddUser}
              variant="contained"
              color="primary"
              className="margin-left-2"
            >
              {editingUser ? "Update User" : "Add User"}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Users;
