import {
  styled
} from "@mui/material";

export const HoverContainer = styled("div")({
  position: "relative",
  "&:hover .commentButton, &:hover .deleteButton, &:hover .inclusionButton": {
    visibility: "visible",
  },
});
