import { Chip } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getComments } from "../helpers";

interface Row {
	chart_id: string;
	type_of_visit: string;
	status: string;
	icd_codes_v2: Record<string, any>;
	[key: string]: any;
}

interface Column {
	title: string;
	column: string;
}

interface BasicTableProps {
	keys: Column[];
	data: Row[];
}

export default function BasicTable({ data }: BasicTableProps) {
	const [commentsCount, setCommentsCount] = useState<{ [key: string]: number }>(
		{},
	);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});

	useEffect(() => {
		const fetchCommentsCount = async () => {
			const counts: { [key: string]: number } = {};
			for (const row of data) {
				const { data: comments } = await getComments(row.chart_id);
				counts[row.chart_id] = comments ? comments.length : 0;
			}
			setCommentsCount(counts);
		};
		fetchCommentsCount();
	}, [data]);

	const columns: GridColDef[] = [
		{
			field: "chart_id",
			headerName: "Chart ID",
			flex: 1,
		},
		{
			field: "type_of_visit",
			headerName: "Type of Visit",
			flex: 1,
			valueGetter: (params) => params || "N/A",
		},
		{
			field: "icd_codes_v3",
			headerName: "# of billable codes",
			flex: 1,
			renderCell: (params) => params.row.icd_codes_v3?.length,
		},
		{
			field: "status",
			headerName: "Status",
			flex: 1,
			renderCell: (params) => (
				<Chip
					color={params.value === "completed" ? "success" : "warning"}
					variant="filled"
					label={params.value || "In Progress"}
				/>
			),
		},
		{
			field: "commentsCount",
			headerName: "Comments",
			flex: 1,
			renderCell: (params) => commentsCount[params.row.chart_id] || 0,
		},
		{
			field: "actions",
			headerName: "Actions",
			flex: 1,
			renderCell: (params) => (
				<Link to={`/patient-details/${params.row.chart_id}`}>VIEW</Link>
			),
		},
	];

	if (!data) return <p>No data available</p>;

	return (
		<DataGrid
			rows={data}
			columns={columns}
			paginationModel={paginationModel}
			onPaginationModelChange={(model) => setPaginationModel(model)}
			slots={{
				toolbar: GridToolbar,
			}}
			getRowId={(row) => row.chart_id}
		/>
	);
}
