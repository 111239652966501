import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EditNoteIcon from "@mui/icons-material/EditNote";
import MicIcon from "@mui/icons-material/Mic";

import {
	Box,
	Button,
	Container,
	List,
	ListItem,
	Tab,
	Tabs,
	TextField,
} from "@mui/material";
import mammoth from "mammoth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import TurndownService from "turndown";
import ErrorComponent from "../components/ErrorComponent";
import FileMode from "../components/UploadFiles/FileMode";
import RecordMode from "../components/UploadFiles/RecordMode";
import ViewFileAccordian from "../components/UploadFiles/ViewFileAccordian";
import {
	createEncounter,
	generateChartId,
	mixpanelConfig,
	updateEncounterRawChart,
	uploadAudioFile,
	uploadFile,
} from "../helpers";
import { fetchData } from "../hooks/useFetchData";

enum UploadMode {
	FILE = 0,
	RECORD = 1,
	TEXT = 2,
}

interface FilePreview {
	id: number;
	name: string;
	content: string;
	file: File;
	status: "pending" | "processing" | "complete" | "error";
	chartId?: number;
}

const UploadFiles: React.FC = () => {
	const [mode, setMode] = useState<UploadMode>(UploadMode.FILE);
	const [files, setFiles] = useState<FilePreview[]>([]);
	const [text, setText] = useState<string>("");
	// const [transcription, setTranscription] = useState<string>("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
	const [isTranscribing, setIsTranscribing] = useState(false);
	const [transcriptionStatus, setTranscriptionStatus] = useState<
		"idle" | "transcribing" | "complete" | "error"
	>("idle");
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const storedUser = localStorage.getItem("user");
	const email = storedUser ? JSON.parse(storedUser).email : null;
	const mixpanel = mixpanelConfig(email);

	const handleAudioRecorded = (blob: Blob) => {
		setAudioBlob(blob);
	};

	const transcribeAudio = async (filePath: string): Promise<string> => {
		const transcript = await fetchData("/transcribe", {
			audio_object: filePath,
		});
		if (transcript.result?.status === 500) {
			throw new Error(transcript.result.message);
		}
		return transcript.result;
	};

	const handleTranscribe = async () => {
		if (!audioBlob) return;

		setIsTranscribing(true);
		setTranscriptionStatus("transcribing");

		try {
			const fileName = `audio-${Date.now()}.mp3`;
			const audioPath = await uploadAudioFile(audioBlob, fileName);
			const transcribedText = await transcribeAudio(audioPath);

			setTranscriptionStatus("complete");
			setText(transcribedText);
		} catch (error) {
			setTranscriptionStatus("error");
			setErrorMessage(error instanceof Error ? error.message : String(error));
		} finally {
			setIsTranscribing(false);
		}
	};

	const openChart = (chartId: number, event: React.MouseEvent) => {
		const url = `/patient-details/${chartId}`;
		if (event.ctrlKey || event.metaKey || event.button === 1) {
			// Open in new tab if ctrl/cmd+click or middle click
			window.open(url, "_blank");
		} else {
			// Otherwise, navigate in the same tab
			navigate(url);
		}
	};

	//adds to files state to display in list
	const addFileToList = async (file: File, content: string) => {
		if (file && content) {
			setFiles((prevFiles) => [
				...prevFiles,
				{
					id: Date.now(),
					name: file.name,
					content: content,
					file,
					status: "pending",
				},
			]);
		}
	};

	const handleFileUpload = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		try {
			const uploadedFiles = event.target.files;
			if (uploadedFiles) {
				for (const file of Array.from(uploadedFiles)) {
					const fileType = file?.name.split(".").pop()?.toLowerCase();
					try {
						if (fileType === "md") {
							const reader = new FileReader();
							reader.onload = (e) => {
								const content = e.target?.result as string;
								addFileToList(file, content);
							};
							reader.readAsText(file);
						} else if (fileType === "docx") {
							const arrayBuffer = await file.arrayBuffer();
							const { value: html } = await mammoth.convertToHtml({
								arrayBuffer,
							});
							const turndownService = new TurndownService();
							const markdown = turndownService.turndown(html);
							await addFileToList(file, markdown);
						} else if (fileType === "pdf") {
							addFileToList(file, "Content shown below");
						} else {
							throw new Error(
								`File ${file.name} is not a markdown, docx, or pdf file and will be skipped.`,
							);
						}
					} catch (fileError) {
						console.error(fileError);
						setErrorMessage(
							fileError instanceof Error
								? fileError.message
								: String(fileError),
						);
					}
				}
			}
		} catch (err) {
			console.error(err);
			setErrorMessage(err instanceof Error ? err.message : String(err));
		}
	};

	const handleRemoveFile = (id: number) => {
		setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
	};

	const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setText(event.target.value);
	};

	const handleTranscriptionComplete = (transcribedText: string) => {
		setText(transcribedText);
	};

	const processAudioUpload = async (fileName: string, fileContent: Blob) => {
		const chartId = generateChartId();
		const filePath = await uploadAudioFile(fileContent, fileName);
		await createEncounter(chartId, fileName, filePath);
		await fetchData("/inference", { chart_id: chartId, email });
		return chartId;
	};

	const processUpload = async (
		fileName: string,
		fileContent: Blob,
		text?: string,
	) => {
		const chartId = generateChartId();
		const fileType = fileName.split(".").pop()?.toLowerCase();
		try {
			const filePath = await uploadFile(fileContent, fileName);
			await createEncounter(chartId, fileName, filePath);
			await updateEncounterRawChart(chartId, { raw_chart: text || "" });
			if (fileType === "md") {
				await fetchData("/inference", { chart_id: chartId, email });
			}
			return chartId;
		} catch (error) {
			setErrorMessage(
				`File "${fileName}" already exists. Please choose a different file name.`,
			);
		}
	};

	const processFile = async (file: FilePreview | any, index: number) => {
		setFiles((prevFiles) =>
			prevFiles.map((f, i) =>
				i === index ? { ...f, status: "processing" } : f,
			),
		);
		try {
			const fileType = file.name.split(".").pop()?.toLowerCase();
			const chartId = await processUpload(file.name, file.file);

			if (fileType === "docx") {
				mixpanel.track("Chart - Uploaded DOCX", {
					chartId,
					file: file.name,
				});
				//@ts-ignore
				await updateEncounterRawChart(chartId, { raw_chart: file.content });
				await fetchData("/inference", { chart_id: chartId, email });
			} else if (fileType === "pdf") {
				mixpanel.track("Chart - Uploaded PDF", {
					chartId,
					file: file.name,
				});
				// First call to /convert-gemini
				try {
					const response = await fetchData(
						"/convert-gemini",
						{ chart_id: chartId },
						"POST",
					);

					if (response.ok) {
						const data = await response.json();
						if (data.isReady) {
							addFileToList(file, data.markdown);
						}
						mixpanel.track("Extracted text from PDF", {
							chartId,
							file: file.name,
						});
					} else {
						console.error("Failed to convert file", response);
					}
				} catch (error) {
					console.error("Error in conversion request:", error);
				}

				// Second call to /inference
				await fetchData("/inference", { chart_id: chartId, email });
			}

			setFiles((prevFiles) =>
				prevFiles.map((f, i) =>
					i === index ? { ...f, status: "complete", chartId } : f,
				),
			);
		} catch (error) {
			console.error(`Error processing file ${file.name}:`, error);
			setFiles((prevFiles) =>
				prevFiles.map((f, i) => (i === index ? { ...f, status: "error" } : f)),
			);
			setErrorMessage(
				error instanceof Error
					? error.message
					: `An unexpected error occurred while processing file ${file.name}.`,
			);
		}
	};

	const processTranscription = async () => {
		const transcriptionBlob = new Blob([text], { type: "text/plain" });
		const fileName = `transcription-${Date.now()}.md`;

		try {
			const chartId = await processAudioUpload(fileName, transcriptionBlob);
			mixpanel.track("Chart - Uploaded audio", {
				chartId,
				file: fileName,
			});
			navigate(`/patient-details/${chartId}`);
		} catch (error) {
			console.error("Error processing transcription:", error);
			setErrorMessage(
				error instanceof Error
					? error.message
					: "An unexpected error occurred while processing the transcription. Please try again.",
			);
		}
	};

	const processText = async () => {
		const textBlob = new Blob([text], { type: "text/plain" });
		const fileName = `pasted-text-${Date.now()}.md`;

		try {
			const chartId = await processUpload(fileName, textBlob, text);
			mixpanel.track("Chart - Pasted text", {
				chartId,
				file: fileName,
			});
			navigate(`/patient-details/${chartId}`);
		} catch (error) {
			console.error("Error processing text:", error);
			setErrorMessage(
				error instanceof Error
					? error.message
					: "An unexpected error occurred while processing the text. Please try again.",
			);
		}
	};

	const handleSubmit = async () => {
		setLoading(true);
		setErrorMessage(null);
		try {
			switch (mode) {
				case UploadMode.FILE:
					if (files.length === 0) {
						setErrorMessage("Please upload at least one file.");
						return;
					}
					for (let i = 0; i < files.length; i++) {
						await processFile(files[i], i);
					}
					break;
				case UploadMode.RECORD:
					if (!text) {
						setErrorMessage(
							"Please record and transcribe audio before submitting.",
						);
						return;
					}
					await processTranscription();
					break;
				case UploadMode.TEXT:
					if (!text) {
						setErrorMessage("Please enter some text before submitting.");
						return;
					}
					await processText();
					break;
			}
		} catch (error) {
			console.error("Error during upload:", error);
			setErrorMessage(
				error instanceof Error
					? error.message
					: "An unexpected error occurred during upload. Please try again.",
			);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Container maxWidth="lg">
			<Box my={4}>
				<Tabs
					value={mode}
					onChange={(_: React.SyntheticEvent, newValue: number) =>
						setMode(newValue)
					}
					centered
				>
					<Tab icon={<CloudUploadIcon />} label="Upload Chart" />
					<Tab icon={<MicIcon />} label="Record note" />
					<Tab icon={<EditNoteIcon />} label="Paste Chart" />
				</Tabs>

				{mode === UploadMode.FILE && <FileMode uploadFile={handleFileUpload} />}

				<List
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						width: "100%",
					}}
				>
					{files.map((file, index) => (
						<ListItem key={file.id}>
							<ViewFileAccordian
								file={file}
								handleDelete={handleRemoveFile}
								openChart={openChart}
							/>
						</ListItem>
					))}
				</List>

				{mode === UploadMode.RECORD && (
					<RecordMode
						audioBlob={audioBlob}
						isTranscribing={isTranscribing}
						transcriptionStatus={transcriptionStatus}
						text={text}
						handleTranscriptionComplete={handleTranscriptionComplete}
						handleAudioRecorded={handleAudioRecorded}
						handleTranscribe={handleTranscribe}
						setText={setText}
					/>
				)}

				{mode === UploadMode.TEXT && (
					<TextField
						fullWidth
						multiline
						rows={6}
						value={text}
						onChange={handleTextChange}
						placeholder="Paste your chart here"
					/>
				)}

				<Box mt={4} textAlign="center">
					<Button
						variant="contained"
						color="primary"
						onClick={handleSubmit}
						disabled={
							loading || mode === UploadMode.TEXT
								? !text.length
								: mode === UploadMode.RECORD
									? !audioBlob
									: !files.length
						}
					>
						{loading ? "Uploading..." : "Submit"}
					</Button>
				</Box>

				{errorMessage && <ErrorComponent error={errorMessage} show={true} />}
			</Box>
		</Container>
	);
};

export default UploadFiles;
