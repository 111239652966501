/* Import React libraries */
import {
	type ChangeEvent,
	useCallback,
	useEffect,
	useState,
	useRef,
} from "react";

/* Import external libraries */
import { v4 as uuidv4 } from "uuid";

/* Import Supabase APIs */
import {
	deleteComment,
	getCommentCount,
	getComments,
	saveComment,
	updateComment,
} from "../helpers";
import type { ErrorMessageType } from "../components/PatientDetails/types";

interface Comment {
	comment_id: string;
	author: string;
	text: string;
	edited?: boolean;
}

export const useComments = (
	chartId: string,
	setErrorMessage: (error: ErrorMessageType) => void,
) => {
	const [comments, setComments] = useState<{ [key: string]: Comment[] }>({});
	const [draftComments, setDraftComments] = useState<{ [key: string]: string }>(
		{},
	);
	const [editingComment, setEditingComment] = useState<{
		[key: string]: number | null;
	}>({});
	const [isEditing, setIsEditing] = useState(false);

	const fetchComments = useCallback(
		async (chartId: string) => {
			try {
				const { data: responseData, error } = await getComments(chartId);
				if (!error) {
					if (responseData) {
						const fetchedComments = responseData.reduce(
							(
								acc: Record<string, Comment[]>,
								comment: {
									id: string;
									code: string;
									comment_by: string;
									comment: string;
								},
							) => {
								if (!acc[comment.code]) {
									acc[comment.code] = [];
								}
								acc[comment.code].push({
									comment_id: comment.id,
									author: comment.comment_by,
									text: comment.comment,
								});
								return acc;
							},
							{},
						);
						setComments(fetchedComments);
					} else {
						setComments({});
					}
				} else {
					setErrorMessage({
						message: `Error fetching comments: ${error instanceof Error ? error.message : "Unknown error"}`,
						type: "error",
					});
				}
			} catch (error) {
				if (error instanceof Error) {
					setErrorMessage({
						message: `Error fetching comments: ${error.message}`,
						type: "error",
					});
				} else {
					setErrorMessage({
						message: "An unknown error occurred while fetching comments",
						type: "error",
					});
				}
			}
		},
		[setErrorMessage],
	);

	const fetchedRef = useRef(false);

	useEffect(() => {
		if (chartId && !fetchedRef.current) {
			fetchComments(chartId);
			fetchedRef.current = true;
		}
	}, [chartId, fetchComments]);

	const fetchCommentCount = useCallback(
		async (chartId: string) => {
			try {
				const { count, error } = await getCommentCount(chartId);
				if (error) {
					setErrorMessage({
						message: `Error fetching comment count: ${error.message}`,
						type: "error",
					});
				}
				return count || 0;
			} catch (error) {
				if (error instanceof Error) {
					setErrorMessage({
						message: `Error fetching comment count: ${error.message}`,
						type: "error",
					});
				} else {
					setErrorMessage({
						message: "An unknown error occurred while fetching comment count",
						type: "error",
					});
				}
				return 0;
			}
		},
		[setErrorMessage],
	);

	const handleCommentChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		code: string,
	) => {
		setDraftComments((prevState) => ({
			...prevState,
			[code]: event.target.value,
		}));
	};

	const handleAddComment = async (
		code: string,
		codeType: string,
		author: string,
		comment: string,
	) => {
		try {
			const newCommentId = uuidv4();

			const payload = {
				chart_id: chartId,
				code: code,
				code_type: codeType.toLowerCase(),
				comment: comment,
				comment_by: author,
				comment_id: newCommentId,
			};

			setComments((prevState) => ({
				...prevState,
				[code]: [
					...(prevState[code] || []),
					{
						comment_id: newCommentId,
						author: "Current User",
						text: comment,
					},
				],
			}));

			setDraftComments((prevState) => ({
				...prevState,
				[code]: "",
			}));

			const { data, error } = await saveComment(payload);
			const returnedCommentId = data?.[0]?.id;

			if (!returnedCommentId) {
				setErrorMessage({
					message: `Failed to get comment_id from the response: ${error?.message}`,
					type: "error",
				});
			} else {
				setErrorMessage({
					message: `Added new comment for ${code}`,
					type: "success",
				});
			}

			setComments((prevState) => ({
				...prevState,
				[code]: prevState[code].map((comment) =>
					comment.comment_id === newCommentId
						? { ...comment, comment_id: returnedCommentId }
						: comment,
				),
			}));
		} catch (error: unknown) {
			setErrorMessage({
				message: `Error adding comment: ${error instanceof Error ? error.message : "Unknown error"}`,
				type: "error",
			});
		}
	};

	const handleUpdateComment = async (code: string, author: string) => {
		try {
			const commentToEdit = comments[code][editingComment[code]!];
			await updateComment({
				comment_id: commentToEdit.comment_id,
				new_comment: draftComments[code],
				comment_by: author,
			});

			setComments((prevState) => ({
				...prevState,
				[code]: prevState[code].map((comment, index) =>
					index === editingComment[code]
						? { ...comment, text: draftComments[code], edited: true }
						: comment,
				),
			}));
			setEditingComment((prevState) => ({ ...prevState, [code]: null }));
			setErrorMessage({
				message: `Updated the comment for ${code}`,
				type: "success",
			});
			setIsEditing(false);

			setDraftComments((prevState) => ({
				...prevState,
				[code]: "",
			}));
		} catch (error: unknown) {
			setErrorMessage({
				message: `Error updating comment: ${error instanceof Error ? error.message : "Unknown error"}`,
				type: "error",
			});
		}
	};

	const handleEditComment = (code: string, index: number) => {
		try {
			const comment = comments[code][index];
			if (!comment) {
				setErrorMessage({
					message: `No comment found at index ${index} for code ${code}`,
					type: "error",
				});
				return;
			}
			setDraftComments((prevState) => ({
				...prevState,
				[code]: comment.text,
			}));
			setEditingComment((prevState) => ({
				...prevState,
				[code]: index,
			}));
			setIsEditing(true);
		} catch (error: unknown) {
			setErrorMessage({
				message: `Error editing comment: ${error instanceof Error ? error.message : "Unknown error"}`,
				type: "error",
			});
		}
	};

	const handleDeleteComment = async (code: string, index: number) => {
		try {
			const commentToDelete = comments[code][index];
			if (!commentToDelete || !commentToDelete.comment_id) {
				setErrorMessage({
					message: `Missing comment_id for deleting: ${commentToDelete}`,
					type: "error",
				});
				return;
			}

			await deleteComment(commentToDelete.comment_id);

			setErrorMessage({
				message: `Deleted comment for ${code}`,
				type: "success",
			});

			setComments((prevState) => ({
				...prevState,
				[code]: prevState[code].filter((_, i) => i !== index),
			}));
		} catch (error: unknown) {
			setErrorMessage({
				message: `Error deleting comment: ${error instanceof Error ? error.message : "Unknown error"}`,
				type: "error",
			});
		}
	};

	return {
		comments,
		draftComments,
		handleCommentChange,
		handleAddComment,
		handleUpdateComment,
		handleEditComment,
		handleDeleteComment,
		isEditing,
		fetchComments,
		fetchCommentCount,
	};
};
