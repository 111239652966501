import AddCircle from "@mui/icons-material/AddCircle";
import Close from "@mui/icons-material/Close";
import { Link, Snackbar, type SnackbarCloseReason } from "@mui/joy";
import {
	Autocomplete,
	Box,
	Button,
	Card,
	CardContent,
	Drawer,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import type React from "react";
import { useEffect, useState } from "react";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { ruleTypes } from "../constants";
import { mixpanelConfig } from "../helpers";
import { supabase } from "../services/supabase";
import { RuleList } from "./RuleList";

interface Rule {
	id: number;
	code: string;
	inclusion: string;
	exclusion: string;
	source: string;
}

const CodesListing: React.FC = () => {
	const [data, setData] = useState<Rule[]>([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [selectedCode, setSelectedCode] = useState<string | null>(null);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [currentInclusions, setCurrentInclusions] = useState<Rule[]>([]);
	const [currentExclusions, setCurrentExclusions] = useState<Rule[]>([]);
	const [currentEdit, setCurrentEdit] = useState<any>({
		id: 0,
		text: "",
		type: "",
		code: "",
	});
	const [showAddForm, setShowAddForm] = useState<boolean>(false);
	const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
	const [deletingId, setDeletingId] = useState<number>(0);
	const [deletingRuleType, setDeletingRuleType] = useState<string>("");
	const [deletingCode, setDeletingCode] = useState<string>("");

	const storedUser = localStorage.getItem("user");
	const email = storedUser ? JSON.parse(storedUser).email : null;
	const mixpanel = mixpanelConfig(email);
	useEffect(() => {
		mixpanel.track("Viewed Rules list");
	}, [mixpanel.track]);

	const handleDeleteIconClick = (
		id: number,
		ruleType: string,
		code: string,
	) => {
		setDeletingId(id);
		setConfirmationOpen(true);
		setDeletingRuleType(ruleType);
		setDeletingCode(code);
	};

	const handleConfirmationClose = () => {
		setConfirmationOpen(false);
		setDeletingId(0);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		const { data, error } = await supabase
			.from("rules")
			.select("*")
			.like("code", "%A00%")
			.order("code");

		if (error) {
			setErrorMessage(error.message);
		}
		setData(data as Rule[]);
	};

	const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);

		mixpanel.track("Searched for code", {
			searchTerm: event.target.value,
		});

		const { data, error } = await supabase
			.from("rules")
			.select("*")
			.like("code", `%${event.target.value}%`);

		if (error) {
			setErrorMessage(error.message);
		} else {
			setData(data);
		}
	};

	const groupedData = data.reduce(
		(
			acc: { [key: string]: { inclusions: Rule[]; exclusions: Rule[] } },
			curr,
		) => {
			if (!acc[curr.code]) {
				acc[curr.code] = { inclusions: [], exclusions: [] };
			}
			if (curr.inclusion) {
				acc[curr.code].inclusions.push(curr);
			}
			if (curr.exclusion) {
				acc[curr.code].exclusions.push(curr);
			}
			return acc;
		},
		{},
	);

	const handleCardClick = (
		code: string,
		inclusions: Rule[],
		exclusions: Rule[],
	) => {
		setSelectedCode(code);
		setCurrentInclusions(inclusions);
		setCurrentExclusions(exclusions);
		setDrawerOpen(true);
	};

	const handleDrawerClose = () => {
		setDrawerOpen(false);
		setSelectedCode(null);
		setCurrentInclusions([]);
		setCurrentExclusions([]);
	};

	const handleRuleUpdate =
		(id: number, type: string) =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setCurrentEdit({
				id: id,
				text: event.target.value,
				type: type,
			});
		};

	const hanldeNewRule = (
		column: string,
		event: React.ChangeEvent<{ value: unknown }>,
	) => {
		setCurrentEdit((prevState: any) => ({
			...prevState,
			[column]: event.target.value,
		}));
	};

	const saveInclusion = async () => {
		try {
			const newRule = {
				code: selectedCode,
				inclusion: currentEdit.type === "inclusion" ? currentEdit.text : null,
				exclusion: currentEdit.type === "exclusion" ? currentEdit.text : null,
				description: currentEdit.text,
				source: "user",
			};

			const { data, error } = await supabase
				.from("rules")
				.insert([newRule])
				.select();

			mixpanel.track("Rule - Added inclusion/exclusion", {
				code: selectedCode,
				type: currentEdit.type,
				text: currentEdit.text,
			});

			if (error) {
				setErrorMessage(error.message);
			} else {
				setErrorMessage(`Added ${data[0].type} for ${data[0].code}`);
				fetchData();
				if (data && data.length > 0) {
					updateCurrentData(0, data[0], currentEdit.type);
				}
				setCurrentEdit({
					id: 0,
					text: "",
					type: "",
				});
			}
		} catch (error) {
			setErrorMessage("Unexpected error occurred");
		}
		setShowAddForm(false);
	};

	const handleRuleSubmit = async () => {
		if (!currentEdit.id) {
			setErrorMessage("No ID provided");
			return;
		}

		try {
			const columnToUpdate = currentEdit.type;
			if (columnToUpdate !== "inclusion" && columnToUpdate !== "exclusion") {
				setErrorMessage("Invalid type");
				return;
			}

			const { data, error } = await supabase
				.from("rules")
				.update({ [columnToUpdate]: currentEdit.text })
				.eq("id", currentEdit.id)
				.select();

			mixpanel.track("Rule - Updated inclusion/exclusion", {
				code: selectedCode,
				type: currentEdit.type,
				newText: currentEdit.text,
			});

			if (error) {
				setErrorMessage(`Error updating rule:${error.message}`);
				setErrorMessage(error.message);
			} else {
				setErrorMessage(
					`${data[0].type} updated successfully for ${data[0].code}`,
				);
				if (data && data.length > 0) {
					updateCurrentData(currentEdit.id, data[0], currentEdit.type);
				}
				fetchData();
				setCurrentEdit({
					id: 0,
					text: "",
					type: "",
				});
			}
		} catch (error: unknown) {
			setErrorMessage(
				`Unexpected error: ${error instanceof Error ? error.message : "Unknown error"}`,
			);
		}
	};

	const updateCurrentData = (ruleId: number, newRule?: Rule, type?: string) => {
		if (newRule) {
			if (type === "inclusion") {
				setCurrentInclusions((prevInclusions) =>
					prevInclusions
						.filter((inclusion) => inclusion.id !== ruleId)
						.concat(newRule),
				);
			} else {
				setCurrentExclusions((prevExclusions) =>
					prevExclusions
						.filter((exclusion) => exclusion.id !== ruleId)
						.concat(newRule),
				);
			}
		} else {
			setCurrentInclusions((prevInclusions) =>
				prevInclusions.filter((inclusion) => inclusion.id !== deletingId),
			);
			setCurrentExclusions((prevExclusions) =>
				prevExclusions.filter((exclusion) => exclusion.id !== deletingId),
			);
		}
	};

	const handleRuleDelete = async () => {
		try {
			const { error } = await supabase
				.from("rules")
				.delete()
				.eq("id", deletingId);

			if (error) {
				setErrorMessage(`Error deleting rule:${error.message}`);
				setErrorMessage(error.message);
			} else {
				setErrorMessage("Rule deleted successfully");
				fetchData();
				updateCurrentData(deletingId);
				setCurrentEdit({
					id: 0,
					text: "",
					type: "",
				});
			}
		} catch (error: unknown) {
			setErrorMessage(
				`Error editing comment: ${error instanceof Error ? error.message : "Unknown error"}`,
			);
		}
		setConfirmationOpen(false);
		setDeletingId(0);
		setDeletingRuleType("");
		setDeletingCode("");
	};

	return (
		<div style={{ padding: 20 }}>
			<Box
				sx={{
					position: "sticky",
					top: "6%",
					zIndex: 1,
					backgroundColor: "white",
					padding: "20px 0",
				}}
			>
				<TextField
					label="Search"
					variant="outlined"
					fullWidth
					value={searchTerm}
					onChange={handleSearch}
					helperText="Search a code. Example: A01"
				/>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				autoHideDuration={3000}
				open={!!errorMessage}
				variant={"solid"}
				onClose={(
					event: React.SyntheticEvent<Element, Event> | Event | null,
					reason: SnackbarCloseReason,
				) => {
					if (reason === "clickaway") {
						return;
					}
					setErrorMessage("");
				}}
			>
				{errorMessage}
			</Snackbar>
			<Grid container spacing={3}>
				{Object.entries(groupedData).map(
					([code, { inclusions, exclusions }]) => (
						<Grid item xs={12} md={6} key={code} className="flex">
							<Card
								className="codeCard"
								onClick={() => handleCardClick(code, inclusions, exclusions)}
								sx={{
									"&:hover": {
										backgroundColor: "#f5f5f5",
										cursor: "pointer",
									},
								}}
							>
								<CardContent>
									<Typography variant="h5" component="div">
										{code}
									</Typography>
									<Grid container spacing={2}>
										{inclusions.length > 0 ? (
											<Grid item xs={6} className="text-left">
												<Typography variant="h6">Inclusions:</Typography>
												{inclusions.slice(0, 2).map(({ inclusion }, index) => (
													<Typography variant="body2" key={index}>
														{inclusion}
													</Typography>
												))}
												{inclusions.length > 2 && (
													<Typography
														variant="body2"
														component={Link}
														onClick={() =>
															handleCardClick(code, inclusions, exclusions)
														}
													>
														...more
													</Typography>
												)}
											</Grid>
										) : (
											<Grid item xs={6} className="text-left">
												<Typography variant="h6">Inclusions:</Typography>
												<Typography variant="body2">
													No inclusion terms
												</Typography>
											</Grid>
										)}
										{exclusions.length > 0 ? (
											<Grid item xs={6} className="text-left">
												<Typography variant="h6">Exclusions:</Typography>
												{exclusions.slice(0, 2).map(({ exclusion }, index) => (
													<Typography variant="body2" key={index}>
														{exclusion}
													</Typography>
												))}
												{exclusions.length > 2 && (
													<Typography
														variant="body2"
														component={Link}
														onClick={() =>
															handleCardClick(code, inclusions, exclusions)
														}
													>
														...more
													</Typography>
												)}
											</Grid>
										) : (
											<Grid item xs={6} className="text-left">
												<Typography variant="h6">Exclusions:</Typography>
												<Typography variant="body2">
													No excludes criteria
												</Typography>
											</Grid>
										)}
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					),
				)}
			</Grid>

			<Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
				<Box width={400} p={2}>
					<Grid container className="items-center justify-between">
						<Typography variant="h4" component="div">
							{selectedCode}
						</Typography>
						<IconButton onClick={handleDrawerClose}>
							<Close />
						</IconButton>
					</Grid>
					{currentInclusions.length > 0 && (
						<RuleList
							rules={currentInclusions}
							type="inclusion"
							currentEdit={currentEdit}
							handleRuleUpdate={handleRuleUpdate}
							handleRuleSubmit={handleRuleSubmit}
							handleDeleteIconClick={handleDeleteIconClick}
							setCurrentEdit={setCurrentEdit}
							setShowAddForm={setShowAddForm}
							selectedCode={selectedCode}
						/>
					)}
					<ConfirmationDialog
						open={confirmationOpen}
						onClose={handleConfirmationClose}
						onConfirm={handleRuleDelete}
						ruleType={deletingRuleType}
						code={deletingCode}
					/>
					{currentExclusions.length > 0 && (
						<RuleList
							rules={currentExclusions}
							type="exclusion"
							currentEdit={currentEdit}
							handleRuleUpdate={handleRuleUpdate}
							handleRuleSubmit={handleRuleSubmit}
							handleDeleteIconClick={handleDeleteIconClick}
							setCurrentEdit={setCurrentEdit}
							setShowAddForm={setShowAddForm}
							selectedCode={selectedCode}
						/>
					)}
					<Button
						variant="contained"
						color="primary"
						startIcon={<AddCircle />}
						onClick={() => {
							setShowAddForm(true);
							setCurrentEdit({
								id: 0,
								text: "",
								type: "",
							});
						}}
						disabled={showAddForm}
					>
						Add Inclusion/Exclusion
					</Button>
					{showAddForm && (
						<Grid container>
							<Grid item xs={12}>
								<Autocomplete
									value={currentEdit.type}
									onChange={(e: any, newValue: string | null) =>
										setCurrentEdit((prevState: any) => ({
											...prevState,
											type: newValue,
										}))
									}
									options={ruleTypes}
									sx={{ margin: "1rem 0" }}
									renderInput={(params: any) => (
										<TextField {...params} label="Rule Type" />
									)}
								/>
								<TextField
									required
									label={
										currentEdit.type
											? `Add new ${currentEdit.type}`
											: "Add new Rule"
									}
									name="rule"
									variant="outlined"
									fullWidth
									value={currentEdit.text}
									onChange={(e: any) => hanldeNewRule("text", e)}
									sx={{ marginBottom: "1rem" }}
								/>
								<Button
									variant="contained"
									color="primary"
									onClick={saveInclusion}
									disabled={!(currentEdit.type && currentEdit.text)}
								>
									Add
								</Button>
								<Button
									variant="contained"
									color="secondary"
									onClick={() => setShowAddForm(false)}
									className="margin-left-1"
								>
									Cancel
								</Button>
							</Grid>
						</Grid>
					)}
				</Box>
			</Drawer>
		</div>
	);
};

export default CodesListing;
