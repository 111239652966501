import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Hits,
  connectStateResults,
} from "react-instantsearch-dom";
import { useRef, useEffect, useState } from "react";

const APPLICATION_ID = process.env.REACT_APP_APPLICATION_ID as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;
const INDEX_NAME = process.env.REACT_APP_INDEX_NAME as string;

const searchClient = algoliasearch(APPLICATION_ID, API_KEY);

const HitComponent = ({
  hit,
  onClick,
}: {
  hit: any;
  onClick: (hit: any) => void;
}) => {
  const extension = hit.extension_2 || hit.code || "N/A";
  const block = hit.block || hit.description || "N/A";

  return (
    <div className="hit-component" onClick={() => onClick(hit)}>
      <div>
        <strong className="hit-component-extension">{extension}</strong> {block}
      </div>
    </div>
  );
};

const Results = connectStateResults(
  ({ searchState, searchResults, children }: any) => {
    if (!searchState || !searchState.query) {
      return null;
    }

    if (!searchResults || searchResults.nbHits === 0) {
      return <div>No results found.</div>;
    }

    return <div className="results-container">{children}</div>;
  }
);

export function AlgoliaSearch({
  onResultClick,
}: {
  onResultClick: (hit: any) => void;
}) {
  const searchBoxRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const input = searchBoxRef.current?.querySelector("input");
    if (input) {
      input.style.height = "50px";
    }
  }, []);

  const handleSearchStateChange = (searchState: any) => {
    setQuery(searchState.query || "");
  };

  const handleResultClick = (hit: any) => {
    onResultClick(hit);
    setQuery("");
  };

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={INDEX_NAME}
      onSearchStateChange={handleSearchStateChange}
    >
      <div ref={searchBoxRef} style={{ width: "100%", position: "relative" }}>
        <SearchBox
          className="custom-search-box"
          translations={{
            submitTitle: "Search",
            resetTitle: "Clear",
          }}
        />
        {query && (
          <Results>
            <Hits
              hitComponent={(props) => (
                <HitComponent {...props} onClick={handleResultClick} />
              )}
            />
          </Results>
        )}
      </div>
    </InstantSearch>
  );
}

export default AlgoliaSearch;
