import React from "react";
import { Button, Tooltip, Typography, styled } from "@mui/material";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";

const HoverContainer = styled("div")({
  position: "relative",
  "&:hover .commentButton": {
    visibility: "visible",
  },
});

interface CommentButtonWithTooltipProps {
  commentsCount: number;
  onClick: () => void;
}

const CommentButtonWithTooltip: React.FC<CommentButtonWithTooltipProps> = ({
  commentsCount,
  onClick,
}) => {
  return (
    <HoverContainer>
      <Tooltip title={`${commentsCount} comments`} arrow>
        <Button
          className="commentButton"
          variant="text"
          onClick={onClick}
          sx={{
            visibility: commentsCount ? "visible" : "hidden",
          }}
        >
          <ChatBubbleOutlineOutlinedIcon
            sx={{
              fontSize: "0.8rem",
              color: "grey.600",
            }}
          />
          <Typography
            sx={{
              marginLeft: 0.5,
              fontSize: "0.8rem",
              color: "slategray",
            }}
          >
            {commentsCount}
          </Typography>
        </Button>
      </Tooltip>
    </HoverContainer>
  );
};

export default CommentButtonWithTooltip;
