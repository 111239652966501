// src/components/Roles.tsx
import React, { useState, useEffect } from "react";
import { supabase } from "../services/supabase";
import {
  TextField,
  Button,
  Typography,
  IconButton,
  Autocomplete,
  Chip,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import { Snackbar, SnackbarCloseReason } from "@mui/joy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircle from "@mui/icons-material/AddCircle";

interface Role {
  id: number;
  name: string;
  permissions: string[];
}

const permissionsOptions = [
  "add_roles",
  "add_codes",
  "add_comments",
  "add_users",
];

const Roles = () => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [newRole, setNewRole] = useState({
    name: "",
    permissions: [] as string[],
  });
  const [editingRole, setEditingRole] = useState<Role | null>(null);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    const { data, error } = await supabase.from("roles").select("*");
    if (error) setErrorMessage(error.message);
    else setRoles(data);
  };

  const handleAddRole = async () => {
    if (editingRole) {
      const { data, error } = await supabase
        .from("roles")
        .update(newRole)
        .eq("id", editingRole.id)
        .select();
      if (error) setErrorMessage(error.message);
      else {
        setErrorMessage("Saved " + editingRole.name + " successfully..!");
        setRoles(
          roles.map((role) => (role.id === editingRole.id ? data[0] : role))
        );
        setEditingRole(null);
      }
    } else {
      const { data, error } = await supabase
        .from("roles")
        .insert([newRole])
        .select();
      if (error) setErrorMessage(error.message);
      else {
        setErrorMessage("Created new role - " + newRole.name);
        setRoles([...roles, ...data]);
      }
    }
    setNewRole({ name: "", permissions: [] });
  };

  const handleDeleteRole = async (id: number) => {
    const { error } = await supabase.from("roles").delete().eq("id", id);
    if (error) setErrorMessage(error.message);
    else {
      setErrorMessage(
        "Deleted Role - " + roles.filter((role) => role.id === id)[0].name
      );
      setRoles(roles.filter((role) => role.id !== id));
    }
  };

  const handleEditRole = (role: Role) => {
    setNewRole({ name: role.name, permissions: role.permissions });
    setShowForm(true);
    setEditingRole(role);
  };

  return (
    <Grid container p={4} className="justify-center">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        open={!!errorMessage}
        variant={"solid"}
        onClose={(
          event: React.SyntheticEvent<Element, Event> | Event | null,
          reason: SnackbarCloseReason
        ) => {
          if (reason === "clickaway") {
            return;
          }
          setErrorMessage("");
        }}
      >
        {errorMessage}
      </Snackbar>
      <Typography variant="h4">Roles Management</Typography>
      <Grid container className="items-center justify-center">
        <Grid item xs={6} p={4}>
          <Table>
            <TableHead>
              <TableCell>Role</TableCell>
              <TableCell>Permissions</TableCell>
              <TableCell>Actions</TableCell>
            </TableHead>
            <TableBody>
              {roles.map((role) => (
                <TableRow key={role.id}>
                  <TableCell>{role.name}</TableCell>
                  <TableCell>{role.permissions.join(", ")}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEditRole(role)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteRole(role.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            onClick={() => {
              setShowForm(true);
              setEditingRole(null);
              setNewRole({ name: "", permissions: [] });
            }}
            variant="contained"
            color="primary"
            startIcon={<AddCircle />}
            sx={{ marginTop: "1rem" }}
          >
            Add Role
          </Button>
        </Grid>

        {showForm && (
          <Grid item xs={6} p={4}>
            <Typography variant="body1" mb={2}>
              {editingRole ? "Update Role" : "Add Role"}
            </Typography>
            <TextField
              label="Role Name"
              value={newRole.name}
              onChange={(e: any) =>
                setNewRole({ ...newRole, name: e.target.value })
              }
              fullWidth
            />
            <Autocomplete
              multiple
              options={permissionsOptions}
              getOptionLabel={(option: string) => option}
              value={newRole.permissions}
              onChange={(event: any, newValue: string[]) =>
                setNewRole({ ...newRole, permissions: newValue })
              }
              renderTags={(value: string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Permissions"
                  placeholder="Select permissions"
                />
              )}
              sx={{ margin: "1rem 0" }}
            />
            <Button onClick={() => setShowForm(false)} color="warning">
              Cancel
            </Button>
            <Button
              onClick={handleAddRole}
              variant="contained"
              color="primary"
              className="margin-left-2"
            >
              {editingRole ? "Update Role" : "Add Role"}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Roles;
