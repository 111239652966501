import {
	Box,
	Button,
	LinearProgress,
	Paper,
	TextField,
	Typography,
} from "@mui/material";
import React from "react";
import SimpleRecordButton from "../Recorder";
import { RecordModeProps } from "./types";

const RecordMode: React.FC<RecordModeProps> = ({
	audioBlob,
	isTranscribing,
	transcriptionStatus,
	text,
	handleTranscriptionComplete,
	handleAudioRecorded,
	handleTranscribe,
	setText,
}) => {
	return (
		<Paper variant="outlined" sx={{ p: 6, mt: 2 }}>
			<Box>
				<SimpleRecordButton
					onTranscriptionComplete={handleTranscriptionComplete}
					onAudioRecorded={handleAudioRecorded}
				/>
				{audioBlob && (
					<Button
						variant="contained"
						color="secondary"
						onClick={handleTranscribe}
						disabled={isTranscribing}
						sx={{ mt: 2 }}
					>
						{isTranscribing ? "Transcribing..." : "Transcribe"}
					</Button>
				)}
				{transcriptionStatus === "transcribing" && (
					<Box sx={{ width: "100%", mt: 2 }}>
						<LinearProgress />
					</Box>
				)}
				{transcriptionStatus === "error" && (
					<Typography color="error" sx={{ mt: 2 }}>
						An error occurred during transcription. Please try again.
					</Typography>
				)}
				{text && (
					<TextField
						fullWidth
						multiline
						rows={4}
						value={text}
						onChange={(
							e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
						) => setText(e.target.value)}
						sx={{ mt: 2 }}
					/>
				)}
			</Box>
		</Paper>
	);
};

export default RecordMode;
