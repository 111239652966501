import {
	default as AddCircle,
	default as AddCircleIcon,
} from "@mui/icons-material/AddCircle";
import Close from "@mui/icons-material/Close";
import {
	Box,
	Button,
	CircularProgress,
	Container,
	Drawer,
	Grid,
	IconButton,
	Paper,
} from "@mui/material";
import type React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { Typography } from "@mui/joy";
import AddCodeDrawer from "../components/AddCodeDrawer";
import { CPTComponentWrapper } from "../components/CPTComponent";
import { CodeStages } from "../components/CodeStages";
import CommentButtonWithTooltip from "../components/CommentsButtonWithTooltip";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { ICDComponentWrapper } from "../components/ICDComponent";
import UpdateUnitsDialog from "../components/PatientDetails/UpdateUnitsDialog";
import { customStyles, ruleTypes } from "../constants";

import ErrorComponent from "../components/ErrorComponent";
import AddRulesForm from "../components/PatientDetails/AddRulesForm";
import CodeDeleteDialog from "../components/PatientDetails/CodeDeleteDialog";
import PatientDetailsHeader from "../components/PatientDetails/PatientDetailsHeader";
import type {
	CPTCode,
	CodeData,
	CodeUnit,
	ErrorMessageType,
} from "../components/PatientDetails/types";
import {
	deleteCodeFromEncounter,
	deleteComment,
	fetchRule,
	getEncounterData,
	mixpanelConfig,
	saveCodeToEncounter,
	updateEncounterData,
} from "../helpers";
import CommentDrawer from "../hooks/commentDrawer";
import { useComments } from "../hooks/useComments";
import { supabase } from "../services/supabase";
import { RuleList } from "./RuleList";

interface ICDCode {
	tag: string | null;
	code: string;
	description: string;
	justification: string;
	highlight?: string;
	source?: string;
	status?: string;
	units?: number;
}

interface Rule {
	id: number;
	code: string;
	inclusion: string;
	exclusion: string;
	source: string;
}

interface UpdateEncounterDataPayload {
	icd_codes_v3: Array<{
		tag: string;
		code: string;
		description: string;
		justification: string;
		highlight: string;
		source?: string;
		status?: string;
		units?: number;
	}>;
	cpt_codes_v3: Array<{
		code: string;
		description: string;
		justification: string;
		emFlag: boolean;
		source?: string;
		status?: string;
		units?: number;
	}>;
}

const PatientDetails: React.FC = () => {
	const { patientId } = useParams();
	const codesCardRef = useRef<HTMLDivElement>(null);
	const [codeData, setCodeData] = useState<CodeData>({
		icd_codes_v2: {},
		icd_codes_v3: [],
		cpt_codes_v3: [],
		cpt_codes_v2: {},
		type_of_visit: "",
		status: "",
		raw_chart: "",
		uploaded_files: [],
	});

	const [loader, setLoader] = useState<boolean>(true);
	const [errorMessage, setErrorMessage] = useState<ErrorMessageType | null>(
		null,
	);
	const [commentDrawerOpen, setCommentDrawerOpen] = useState<boolean>(false);
	const [codeDrawerOpen, setCodeDrawerOpen] = useState<boolean>(false);
	const [selectedCommentKey, setSelectedCommentKey] = useState<string>("");
	const [selectedCodeType, setSelectedCodeType] = useState<string>("");
	const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
	const [codeToDelete, setCodeToDelete] = useState<string>("");
	const [startPoll, setStartPoll] = useState<boolean>(false);
	const [rulesData, setRulesData] = useState<{ [key: string]: string }>({
		"1": "value",
	});
	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
	const [currentCode, setCurrentCode] = useState<string>("");
	const [currentInclusions, setCurrentInclusions] = useState<any[]>([]);
	const [currentExclusions, setCurrentExclusions] = useState<any[]>([]);
	const [currentEdit, setCurrentEdit] = useState<any>({
		id: 0,
		text: "",
		type: "",
		code: "",
	});
	const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
	const [deletingId, setDeletingId] = useState<number>(0);
	const [deletingRuleType, setDeletingRuleType] = useState<string>("");
	const [deletingCode, setDeletingCode] = useState<string>("");
	const [showAddForm, setShowAddForm] = useState<boolean>(false);
	const [highlightString, setHighlightString] = useState<string>("");
	const [openCodeUnitsDialog, setOpenCodeUnitsDialog] =
		useState<boolean>(false);
	const [exportCodesData, setExportCodesData] = useState<any[]>([]);
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
	const [updatedData, setUpdatedData] = useState<CodeUnit[]>([]);
	const [isCoderSubmitted, setIsCoderSubmitted] = useState(null);

	const storedUser = localStorage.getItem("user");
	const email = storedUser ? JSON.parse(storedUser).email : null;
	const mixpanel = mixpanelConfig(email);
	const isCoder = true;

	useEffect(() => {
		mixpanel.track("Viewed Chart", {
			chartId: patientId,
		});
	}, [patientId, mixpanel.track]);

	const setErrorMessageWrapper = useCallback((error: ErrorMessageType) => {
		setErrorMessage(error);
	}, []);

	const {
		comments,
		draftComments,
		handleCommentChange,
		handleAddComment,
		handleUpdateComment,
		handleEditComment,
		handleDeleteComment,
		isEditing,
	} = useComments(patientId?.toString() || "", setErrorMessageWrapper);

	useEffect(() => {
		const loadData = async () => {
			try {
				const { data: responseData, error: encounterError } =
					await getEncounterData(patientId);

				if (encounterError) {
					setErrorMessage({
						message:
							(encounterError as Error).message || "An unknown error occurred",
						type: "error",
					});
					return;
				}

				setCodeData(responseData as CodeData);
				setIsCoderSubmitted(
					Array.isArray(responseData)
						? false
						: responseData?.has_coder_submitted,
				);

				if (!Array.isArray(responseData) && !responseData?.icd_codes_v3) {
					setStartPoll(true);
				} else {
					const codeData = Array.isArray(responseData)
						? responseData[0]
						: responseData;
					const icdCodes = codeData.icd_codes_v3.map(
						(code: ICDCode) => code.code,
					);
					const cptCodes = codeData.cpt_codes_v3.map(
						(code: CPTCode) => code.code,
					);
					const codes = [...icdCodes, ...cptCodes];
					fetchRules(codes);
					setLoader(false);
				}
			} catch (error: any) {
				setLoader(false);
				setErrorMessage({
					message: error.message,
					type: "error",
				});
			}
		};

		loadData();
	}, [patientId]);

	useEffect(() => {
		let intervalId: NodeJS.Timeout;

		if (startPoll) {
			intervalId = setInterval(async () => {
				try {
					const { data: responseData, error: encounterError } =
						await getEncounterData(patientId);

					if (encounterError) {
						setErrorMessage({
							message:
								(encounterError as Error).message ||
								"An unknown error occurred",
							type: "error",
						});
						return;
					}

					if (
						!Array.isArray(responseData) &&
						responseData?.icd_codes_v3 &&
						responseData?.cpt_codes_v3
					) {
						setStartPoll(false);
						setCodeData(responseData as CodeData);
						setLoader(false);
						clearInterval(intervalId);
					}
				} catch (error: any) {
					setErrorMessage({
						message: `Error fetching status${error}`,
						type: "error",
					});
				}
			}, 2000);
		}

		return () => clearInterval(intervalId);
	}, [startPoll, patientId]);

	//To set codes, codeTypes in exportCodesData on mounting, to display them in units dialog table
	useEffect(() => {
		const codesToExport: { code_type: string; code: string }[] = [];
		if (codeData?.cpt_codes_v3 && codeData.cpt_codes_v3?.length > 0) {
			for (const item of codeData.cpt_codes_v3) {
				if (item.code && item.code.trim() !== "") {
					codesToExport.push({
						code_type: "cpt",
						code: item.code,
					});
				}
			}

			setExportCodesData(codesToExport);
		}
	}, [codeData]);

	const handleOpenUnitsDialog = () => {
		setOpenCodeUnitsDialog(true);
	};

	const toggleCommentDrawer = (open: boolean, key = "", type = "") => {
		setSelectedCommentKey(key);
		setSelectedCodeType(type);
		setCommentDrawerOpen(open);
	};

	const fetchRules = async (codes: string[]) => {
		const { data: rulesData, error: rulesError } = await supabase
			.from("rules")
			.select("code, description")
			.in("code", codes);

		if (rulesError) {
			setErrorMessage({
				message: rulesError.message,
				type: "error",
			});
			return;
		}

		const rulesMap = rulesData.reduce(
			(map: any, item: any) => {
				if (!map[item.code]) {
					map[item.code] = item.description;
				}
				return map;
			},
			{} as { [key: string]: string },
		);

		setRulesData(rulesMap);
	};

	const toggleCodeDrawer = (open: boolean) => {
		setCodeDrawerOpen(open);
	};

	const handleAddCode = async (
		newCode: string,
		newDescription: string,
		newComment: string,
		source: string,
	) => {
		if (!newCode) {
			setErrorMessage({
				message: "Cannot add code: No code provided",
				type: "error",
			});
			return;
		}

		try {
			const updatedCodeData = { ...codeData };

			const newCodeData = {
				code: newCode,
				description: newDescription,
				justification: newComment,
				source: "human",
				status: "active",
			};

			if (source === "extension_2") {
				updatedCodeData.icd_codes_v3.push({ ...newCodeData, tag: null });
			} else {
				updatedCodeData.cpt_codes_v3.push({ ...newCodeData, emFlag: false });
			}

			setCodeData(updatedCodeData);

			const payload: UpdateEncounterDataPayload = {
				icd_codes_v3: updatedCodeData.icd_codes_v3.map((code) => ({
					...code,
					tag: code.tag || "",
					highlight: code.highlight || "",
				})),
				cpt_codes_v3: updatedCodeData.cpt_codes_v3.map((code) => ({
					...code,
					emFlag: code.emFlag || false,
				})),
			};

			const { error } = await updateEncounterData(patientId as string, payload);

			if (error) {
				setErrorMessage({
					message: `Error adding code: ${error}`,
					type: "error",
				});
			} else {
				setErrorMessage({
					message: `Saved code to encounter ${patientId}`,
					type: "success",
				});
				mixpanel.track("Added Code", {
					code: newCode,
					codeType: source === "extension_2" ? "icd" : "cpt",
					chartId: patientId,
				});
			}

			await handleAddComment(
				newCode,
				source === "extension_2" ? "icd" : "cpt",
				"Current User",
				newComment,
			);
		} catch (error: any) {
			setErrorMessage({
				message: `Error adding code:${error}`,
				type: "error",
			});
		}
	};

	const handleOpenDeleteDialog = (code: string, codeType: string) => {
		setCodeToDelete(code);
		setSelectedCodeType(codeType);
		setOpenDeleteDialog(true);
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
		setCodeToDelete("");
		setSelectedCodeType("");
	};

	const handleConfirmDelete = async () => {
		try {
			await deleteCodeFromEncounter(
				patientId as string,
				codeToDelete,
				selectedCodeType,
			);
			await deleteComment(codeToDelete);

			// To Update local state
			setCodeData((prevCodeData) => {
				const updatedCodeData = { ...prevCodeData };
				if (selectedCodeType === "icd") {
					updatedCodeData.icd_codes_v3 = updatedCodeData.icd_codes_v3.map(
						(code) =>
							code.code === codeToDelete
								? { ...code, status: "deleted", source: "human" }
								: code,
					);
				} else {
					updatedCodeData.cpt_codes_v3 = updatedCodeData.cpt_codes_v3.map(
						(code) =>
							code.code === codeToDelete
								? { ...code, status: "deleted", source: "human" }
								: code,
					);
				}
				return updatedCodeData;
			});

			mixpanel.track("Deleted Code", {
				code: codeToDelete,
				codeType: selectedCodeType,
				chartId: patientId,
			});

			setErrorMessage({
				message: `Successfully deleted ${codeToDelete}`,
				type: "success",
			});
		} catch (error: any) {
			setErrorMessage({
				message: `Error deleting code: ${error.message}`,
				type: "error",
			});
		} finally {
			handleCloseDeleteDialog();
		}
	};

	const selectedComments = comments[selectedCommentKey] || [];
	const draftComment = draftComments[selectedCommentKey] || "";

	// const existingCodes = [
	//   ...Object.entries(codeData.icd_codes_v2 || {}).map(([code, { label }]) => ({
	//     code,
	//     description: label,
	//   })),
	//   ...Object.entries(codeData.cpt_codes_v2 || {}).map(([code, { label }]) => ({
	//     code,
	//     description: label,
	//   })),
	// ];

	const handleDrawerClose = () => {
		setDrawerOpen(false);
	};

	const handleOpenDrawer = async (code: string) => {
		const { data, error } = await fetchRule(code);
		if (!error) {
			setCurrentCode(code);
			const inclusions =
				data?.filter((entry: Rule) => entry.inclusion !== null) || [];
			const exclusions =
				data?.filter((entry: Rule) => entry.exclusion !== null) || [];
			setCurrentInclusions(inclusions);
			setCurrentExclusions(exclusions);
			setDrawerOpen(true);
		} else {
			setErrorMessage({
				message: error.message,
				type: "error",
			});
		}
	};

	const handleRuleUpdate =
		(id: number, type: string) =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setCurrentEdit({
				id: id,
				text: event.target.value,
				type: type,
			});
		};

	const handleRuleSubmit = async () => {
		if (!currentEdit.id) {
			setErrorMessage({
				message: "No ID provided",
				type: "error",
			});
			return;
		}

		try {
			const columnToUpdate = currentEdit.type;
			if (columnToUpdate !== "inclusion" && columnToUpdate !== "exclusion") {
				setErrorMessage({
					message: "Invalid type",
					type: "error",
				});
				return;
			}

			const { data, error } = await supabase
				.from("rules")
				.update({ [columnToUpdate]: currentEdit.text })
				.eq("id", currentEdit.id)
				.select();

			if (error) {
				setErrorMessage({
					message: `Error updating rule:${error.message}`,
					type: "error",
				});
			} else {
				setErrorMessage({
					message: `${currentEdit.type} updated successfully for ${data[0].code}`,
					type: "success",
				});
				if (data && data.length > 0) {
					updateCurrentData(currentEdit.id, data[0], currentEdit.type);
				}
				setCurrentEdit({
					id: 0,
					text: "",
					type: "",
				});
			}
		} catch (error: unknown) {
			setErrorMessage({
				message: `Unexpected error: ${error instanceof Error ? error.message : "Unknown error"}`,
				type: "error",
			});
		}
	};

	const handleHighlighting = (highlight: string) => {
		mixpanel.track("Highlighted Reference", {
			code: codeToDelete,
			codeType: selectedCodeType,
			highlight,
			chartId: patientId,
		});
		setHighlightString(highlight);
	};

	const HighlightedText: React.FC<{ text: string; highlight?: string }> = ({
		text,
		highlight,
	}) => {
		if (!text) {
			return <>{text || ""}</>;
		}
		if (!highlight) {
			return <>{text}</>;
		}
		// console.log('text', text);
		// return <>{text}</>;

		const parts = text.split(new RegExp(`(${highlight})`, "gi"));
		return (
			<>
				{parts.map((part, index) =>
					part.toLowerCase() === highlight.toLowerCase() ? (
						<span
							key={index}
							style={{ textDecoration: "underline", cursor: "pointer" }}
							onClick={() => handleHighlighting(part)}
						>
							{part}
						</span>
					) : (
						part
					),
				)}
			</>
		);
	};

	const updateCurrentData = (ruleId: number, newRule?: Rule, type?: string) => {
		if (newRule) {
			if (type === "inclusion") {
				setCurrentInclusions((prevInclusions) =>
					prevInclusions
						.filter((inclusion) => inclusion.id !== ruleId)
						.concat(newRule),
				);
			} else {
				setCurrentExclusions((prevExclusions) =>
					prevExclusions
						.filter((exclusion) => exclusion.id !== ruleId)
						.concat(newRule),
				);
			}
		} else {
			setCurrentInclusions((prevInclusions) =>
				prevInclusions.filter((inclusion) => inclusion.id !== deletingId),
			);
			setCurrentExclusions((prevExclusions) =>
				prevExclusions.filter((exclusion) => exclusion.id !== deletingId),
			);
		}
	};

	const handleDeleteIconClick = (
		id: number,
		ruleType: string,
		code: string,
	) => {
		setDeletingId(id);
		setConfirmationOpen(true);
		setDeletingRuleType(ruleType);
		setDeletingCode(code);

		mixpanel.track("Deleted Rule", {
			code,
			codeType: selectedCodeType,
			chartId: patientId,
			ruleType,
		});
	};

	const handleConfirmationClose = () => {
		setConfirmationOpen(false);
		setDeletingId(0);
	};

	const handleRuleDelete = async () => {
		try {
			const { error } = await supabase
				.from("rules")
				.delete()
				.eq("id", deletingId);

			if (error) {
				setErrorMessage({
					message: `Error deleting rule:${error.message}`,
					type: "error",
				});
			} else {
				setErrorMessage({
					message: "Rule deleted successfully",
					type: "success",
				});
				updateCurrentData(deletingId);
				setCurrentEdit({
					id: 0,
					text: "",
					type: "",
				});
			}
		} catch (error: any) {
			setErrorMessage({
				message: `Unexpected error:${error}`,
				type: "error",
			});
		}
		setConfirmationOpen(false);
		setDeletingId(0);
		setDeletingRuleType("");
		setDeletingCode("");
	};

	const saveInclusion = async () => {
		try {
			const newRule = {
				code: currentCode,
				inclusion: currentEdit.type === "inclusion" ? currentEdit.text : null,
				exclusion: currentEdit.type === "exclusion" ? currentEdit.text : null,
				description: currentEdit.text,
				source: "user",
			};

			const { data, error } = await supabase
				.from("rules")
				.insert([newRule])
				.select();

			if (error) {
				setErrorMessage({
					message: error.message,
					type: "error",
				});
			} else {
				setErrorMessage({
					message: `Added ${data[0].type} for ${data[0].code}`,
					type: "success",
				});
				if (data && data.length > 0) {
					updateCurrentData(0, data[0], currentEdit.type);
				}
				setCurrentEdit({
					id: 0,
					text: "",
					type: "",
				});
			}
		} catch (error) {
			setErrorMessage({
				message: "Unexpected error occurred",
				type: "error",
			});
		}
		setShowAddForm(false);
	};

	const handleNewRule = (
		column: string,
		event: React.ChangeEvent<{ value: unknown }>,
	) => {
		setCurrentEdit((prevState: any) => ({
			...prevState,
			[column]: event.target.value,
		}));
	};

	const handleUpdateUnits = async (updatedData: CodeUnit[]) => {
		const updatedCptCodesV3 = codeData.cpt_codes_v3.map((codeData) => {
			const updatedItem = updatedData.find(
				(item) => item.code === codeData.code,
			);

			// Check if the coder has modified units or modifiers
			const hasModifiedUnits =
				updatedItem &&
				(updatedItem.units !== codeData.units ||
					(updatedItem.modifiers || []).length !==
						(codeData.modifiers || []).length ||
					(updatedItem.modifiers || []).some(
						(mod, index) => mod !== (codeData.modifiers || [])[index],
					));

			// If the code has not been modified, return the original codeData
			if (!hasModifiedUnits) {
				return codeData; // No changes, return original
			}

			// If modified, create a new object with coder_saved_units
			return {
				...codeData,
				coder_saved_units: [
					{
						code: codeData.code,
						units: updatedItem.units || codeData.units || 0,
						modifiers: updatedItem.modifiers || [],
					},
				],
			};
		});

		try {
			const { error } = await updateEncounterData(patientId as string, {
				cpt_codes_v3: updatedCptCodesV3,
			});

			if (error) {
				throw new Error(error.message);
			}
			//@ts-ignore
			setCodeData((prevData) => ({
				...prevData,
				cpt_codes_v3: updatedCptCodesV3,
			}));

			setErrorMessage({
				message: "Units updated successfully",
				type: "success",
			});
			setOpenCodeUnitsDialog(false);
		} catch (error) {
			setErrorMessage({
				message: `Error updating units: ${(error as Error).message}`,
				type: "error",
			});
		}
	};
	return (
		<Container maxWidth={false}>
			<Box>
				<ErrorComponent
					error={errorMessage?.message ?? ""}
					show={!!errorMessage}
					type={errorMessage?.type as "info" | "error" | "success" | undefined}
				/>
				{loader && codeData.raw_chart === "" ? (
					<Box className="loaderBox">
						<CircularProgress variant="indeterminate" />
					</Box>
				) : (
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<PatientDetailsHeader
								codeData={codeData as any}
								patientId={patientId || ""}
								customStyles={customStyles}
								highlightString={highlightString}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Paper
								square={false}
								elevation={3}
								className="paperHeight"
								ref={codesCardRef}
							>
								<Box>
									<Grid
										container
										justifyContent={"space-between"}
										alignItems={"center"}
										className="margin-bottom-1"
									>
										<Typography
											level="title-lg"
											style={{ ...customStyles, fontWeight: "bold" }}
										>
											Codes
										</Typography>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												backgroundColor: "#f5f5f5",
												borderRadius: "50px",
												p: 1,
												cursor: "pointer",
											}}
											onClick={() => toggleCodeDrawer(true)}
										>
											<CodeStages codeData={codeData} />
											<AddCircleIcon
												fontSize="small"
												color="primary"
												sx={{ marginLeft: 1 }}
											/>
											<CommentButtonWithTooltip
												commentsCount={comments[patientId || ""]?.length || 0}
												onClick={() =>
													toggleCommentDrawer(true, patientId, "overall")
												}
											/>
										</Box>
									</Grid>
									<Box className="text-left codesBox">
										<Typography
											level="title-md"
											className="margin-left-1"
											style={customStyles}
										>
											ICD
										</Typography>
										{loader ? (
											<Box m={4} p={4}>
												<CircularProgress />
											</Box>
										) : (
											<ICDComponentWrapper
												//@ts-ignore
												codeData={codeData}
												customStyles={customStyles}
												rulesData={rulesData}
												comments={comments}
												handleHighlighting={handleHighlighting}
												toggleCommentDrawer={toggleCommentDrawer}
												handleOpenDeleteDialog={handleOpenDeleteDialog}
												handleOpenDrawer={handleOpenDrawer}
												HighlightedText={HighlightedText}
												patientId={patientId || ""}
											/>
										)}
									</Box>
									<Box className="text-left codesBox">
										<Typography
											className="text-left margin-left-1"
											style={customStyles}
										>
											CPT
										</Typography>
										{loader ? (
											<Box m={4} p={4}>
												<CircularProgress />
											</Box>
										) : (
											<CPTComponentWrapper
												//@ts-ignore
												codeData={codeData}
												customStyles={customStyles}
												rulesData={rulesData}
												comments={comments}
												handleHighlighting={handleHighlighting}
												toggleCommentDrawer={toggleCommentDrawer}
												handleOpenDeleteDialog={handleOpenDeleteDialog}
												handleOpenDrawer={handleOpenDrawer}
												HighlightedText={HighlightedText}
											/>
										)}
									</Box>
									<Box>
										<Button
											className="commentButton"
											variant="contained"
											onClick={() => handleOpenUnitsDialog()}
											sx={{
												margin: 1,
											}}
											disabled={
												!codeData?.cpt_codes_v3 ||
												codeData.cpt_codes_v3.length === 0
											}
										>
											Submit
										</Button>
									</Box>
								</Box>
							</Paper>
							<Drawer
								anchor="right"
								open={drawerOpen}
								onClose={handleDrawerClose}
							>
								<Box width={400} p={2}>
									<Grid container className="items-center justify-between">
										<Typography component="div">{currentCode}</Typography>
										<IconButton onClick={handleDrawerClose}>
											<Close />
										</IconButton>
									</Grid>
									{currentInclusions?.length > 0 && (
										<RuleList
											rules={currentInclusions}
											type="inclusion"
											currentEdit={currentEdit}
											handleRuleUpdate={handleRuleUpdate}
											handleRuleSubmit={handleRuleSubmit}
											handleDeleteIconClick={handleDeleteIconClick}
											setCurrentEdit={setCurrentEdit}
											setShowAddForm={setShowAddForm}
											selectedCode={currentCode}
										/>
									)}
									<ConfirmationDialog
										open={confirmationOpen}
										onClose={handleConfirmationClose}
										onConfirm={handleRuleDelete}
										ruleType={deletingRuleType}
										code={deletingCode}
									/>
									{currentExclusions?.length > 0 && (
										<RuleList
											rules={currentExclusions}
											type="exclusion"
											currentEdit={currentEdit}
											handleRuleUpdate={handleRuleUpdate}
											handleRuleSubmit={handleRuleSubmit}
											handleDeleteIconClick={handleDeleteIconClick}
											setCurrentEdit={setCurrentEdit}
											setShowAddForm={setShowAddForm}
											selectedCode={currentCode}
										/>
									)}
									<Button
										variant="contained"
										color="primary"
										startIcon={<AddCircle />}
										onClick={() => {
											setShowAddForm(true);
											setCurrentEdit({
												id: 0,
												text: "",
												type: "",
											});
										}}
										disabled={showAddForm}
									>
										Add Inclusion/Exclusion
									</Button>
									{showAddForm && (
										<AddRulesForm
											currentEdit={currentEdit}
											setCurrentEdit={setCurrentEdit}
											//@ts-ignore
											ruleTypes={ruleTypes}
											handleNewRule={handleNewRule}
											saveInclusion={saveInclusion}
											setShowAddForm={setShowAddForm}
										/>
									)}
								</Box>
							</Drawer>
						</Grid>
					</Grid>
				)}
			</Box>
			<CommentDrawer
				open={commentDrawerOpen}
				toggleDrawer={(open) => toggleCommentDrawer(open)}
				comments={selectedComments}
				draftComment={draftComment}
				handleCommentChange={(event, code) =>
					handleCommentChange(event, selectedCommentKey)
				}
				handleAddComment={(code) =>
					handleAddComment(code, selectedCodeType, "Current User", draftComment)
				}
				handleUpdateComment={(code) =>
					handleUpdateComment(code, "Current User")
				}
				codeType={selectedCodeType}
				code={selectedCommentKey}
				handleEditComment={handleEditComment}
				handleDeleteComment={(code, index) => handleDeleteComment(code, index)}
				isEditing={isEditing}
			/>
			<AddCodeDrawer
				open={codeDrawerOpen}
				onClose={() => toggleCodeDrawer(false)}
				onSubmit={handleAddCode}
				// existingCodes={existingCodes}
			/>
			<CodeDeleteDialog
				openDeleteDialog={openDeleteDialog}
				handleCloseDeleteDialog={handleCloseDeleteDialog}
				customStyles={customStyles}
				handleConfirmDelete={handleConfirmDelete}
			/>
			{exportCodesData?.length > 0 && (
				<UpdateUnitsDialog
					open={openCodeUnitsDialog}
					onClose={() => setOpenCodeUnitsDialog(false)}
					exportData={exportCodesData}
					cptCodesV3={codeData?.cpt_codes_v3}
					updatedData={updatedData}
					setUpdatedData={setUpdatedData}
					isSubmitDisabled={isSubmitDisabled}
					setIsSubmitDisabled={setIsSubmitDisabled}
					errorMessage={errorMessage?.message ?? ""}
					setErrorMessage={setErrorMessage}
					updateUnits={handleUpdateUnits}
					chartId={Number.parseInt(patientId || "0")}
					coderSubmitted={isCoderSubmitted || false}
					coderSavedUnits={
						codeData?.cpt_codes_v3?.flatMap(
							(code) => code.coder_saved_units || [],
						) || []
					}
					hasQASubmitted={codeData?.has_qa_submitted || false}
				/>
			)}
		</Container>
	);
};

export default PatientDetails;
