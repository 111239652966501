import axios from "axios";
import { remoteURL } from "../constants";

const fetchData = async (
  endpoint: string,
  formData: any = {},
  method: "GET" | "POST" | "DELETE" = "POST"
) => {
  try {
    const url = `${remoteURL}${endpoint}`;
    const response =
      method === "GET"
        ? await axios.get(url)
        : method === "DELETE"
        ? await axios.delete(url, { data: formData })
        : await axios.post(
            url,
            Object.keys(formData).length === 0 ? {} : formData
          );
    return response.data;
  } catch (error: any) {
    console.error(`Error in fetchData: ${error.message}`, error.response?.data);
    throw new Error(
      error.response?.data?.message || "An error occurred while fetching data"
    );
  }
};

export { fetchData };
