import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";

import { anyRole, adminAccess } from "../constants";

interface PrivateRouteProps {
  requiredRole: string;
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<PrivateRouteProps> = ({
  requiredRole = anyRole,
  children,
}) => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  if (
    requiredRole !== anyRole &&
    user.permissions.indexOf(requiredRole) === -1 &&
    user.permissions.indexOf(adminAccess) === -1
  ) {
    return <Navigate to="/not-authorized" />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
